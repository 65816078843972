.heroContainer {
  display: flex;
  align-items: center;
  background-color: var(--codeville-color-white);
  padding: 88px 130px 160px 175px;
  min-height: 700px;
  height: fit-content;
  position: relative;
}

.heroContainer h1 {
  font-weight: 600;
  font-size: 60px;
  line-height: 120%;
  letter-spacing: -1px;
  margin-bottom: 24px;
  max-width: 636px;
}

.heroContent {
  display: flex;
  flex-direction: column;
  max-width: 636px;
  margin-right: 87px;
}

.heroContent::after {
  position: absolute;
  content: "";
  /* background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 22L9.45698e-07 22L1.90735e-06 -9.6165e-07L22 22Z' fill='%239DE3EB'/%3E%3C/svg%3E%0A"); */
  height: 22px;
  width: 22px;
  left: 34px;
  top: 94px;
  border-radius: 0px;
}

.heroContent p {
  font-size: 18px;
  max-width: 441px;
  margin-bottom: 48px;
}

.heroImageContainer {
  position: relative;
}

.heroImageContainer img {
  width: 100%;
}

.heroImageContainer::after {
  position: absolute;
  content: "";
  background-image: url("../../../../assets/images/codeville/homeHeroDoodle.png");
  height: 108px;
  width: 108px;
  left: -126px;
  bottom: calc(0px - 54px);
  border-radius: 0px;
}

.actionContainer{
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1.5rem;
}

.secondaryButton{
  display: inline-flex;
  align-items: center;
  gap: .5rem;
  padding: 1rem 1.5rem;
  color: #596275;
  background-color: #ffffff;
  border: 1px solid;
  border-radius: 2rem;
  cursor: pointer;
}

.secondaryButton:hover{
  color: rgba(239, 41, 71, 0.68);
  /* box-shadow: 0px 4px 10px rgba(239, 41, 71, 0.68); */
}

.nofillIcon, .nofillIcon path{
  fill: none !important;
}


/* Brochure Modal */
.BrochureModal{
  padding: 50px 25px;
  text-align: center;
}

.BrochureModalWrapper{
  max-width: 400px;
  margin: auto;
}

.BrochureModal h2  {
  color: var(--codeville-color-space-cadet);
  margin-bottom: 12px;
  font-size: 1.5rem;
}


.BrochureModal p {
  font-size: 1rem;
  font-weight: 300;
  color: rgba(89, 98, 117, 1);
  margin-bottom: 15px;
  line-height: 23px;
}

.BrochureModal button {
  margin: 0 auto;
}

.inputGroup{
  display: block;
  padding: .5rem 1rem;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  margin: 30px auto;
}

.inputGroup input{
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
}

.inputGroup:focus-within{
  border-color: #ee2646;
}

@media screen and (max-width: 1435px) {
  .heroContainer {
    padding: 68px 130px 160px 130px;
  }

  .heroContainer h1 {
    font-size: 48px;
  }
}

@media screen and (max-width: 1210px) {
  .heroContainer {
    padding: 68px 60px 160px 60px;
  }
}

@media screen and (max-width: 970px) {
  .heroContainer {
    flex-direction: column;
    align-items: flex-start;
  }

  .heroContent {
    margin-right: 0px;
  }

  .heroContent::after {
    left: 34px;
    top: 0px;
  }

  .heroImageContainer {
    width: 100%;
    max-width: 423px;
    max-height: 414.40655517578125px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 576px) {
  .heroContainer {
    padding: 68px 30px 50px 30px;
  }
  .heroContainer h1 {
    font-size: 34px;
  }

  .heroContainer p {
    font-size: 16px;
    margin-bottom: 40px;
  }
}
