.galleryContainer {
  background: linear-gradient(273.75deg, #e13f29 -1.55%, #fb7a68 -1.54%, #ec1840 104.27%), #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 116px 100px;
}

.galleryContainer h2 {
  color: var(--codeville-color-white);
  font-weight: 600;
  font-size: 48px;
  text-align: center;
}

.gradfacesContainer {
  margin-top: 86px;
  position: relative;
}

.gradfacesContainer img {
  object-fit: cover;
  width: 100%;
}

.gradfacesIcon {
  position: absolute;
  left: 0px;
  bottom: 0px;
  transform: translate(-50%, 50%);
}

@media screen and (max-width: 700px) {
  .galleryContainer {
    padding: 50px 16px;
  }

  .gradfacesIcon {
    width: 60px;
  }
}
@media screen and (max-width: 450px) {
  .galleryContainer h2 {
    font-size: 30px;
  }

  .gradfacesContainer {
    margin-top: 30px;
  }
}
