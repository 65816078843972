.step {
  display: flex;
  /* background: red; */
  width: 100%;
}

.step-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 1px dashed var(--genesys-blue);
  margin-right: 20px;
  margin-bottom: 60px;
}

.dotted::after {
  position: relative;
  top: 50px;
  left: -10px;
  content: url("../../assets/svg/dottedLine.svg");
  display: block;
  width: 1px;
  height: 30px;
}

.step h1 {
  color: var(--genesys-blue);
  font-family: var(--genesys-font-roboto);
  font-size: 36px !important;
  font-weight: 900;
}

.step h2 {
  color: var(--genesys-blue);
  font-family: var(--genesys-font-roboto);
  font-size: 20px;
  font-weight: 900;
  line-height: 26px;
  margin-bottom: 14px;
}

.step p {
  color: var(--genesys-grey);
  font-family: var(--genesys-font-roboto);
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
}

.text-container {
  width: 70%;
}

@media screen and (min-width: 768px) {
  .step h1 {
    font-size: 24px;
  }
}

@media screen and (min-width: 992px) {
  .step h1 {
    font-size: 24px;
  }

  .text-container {
    width: 45%;
  }
}
