.pastGraduates {
  position: relative;
  background: url("./../../assets/images/codeville/gallery/gray-white-pixelated.png");
  background-size: cover;
  /* background-attachment: fixed; */
  display: grid;
  gap: 3rem;
}

.pastGraduates_header {
  display: grid;
  gap: 2rem;
  text-align: center;
  padding: 0 1.5rem;
}

.pastGraduates_header span {
  color: #56c2df;
}

.pastGraduates_header h1 {
  font-size: 32px;
  font-weight: 600;
  color: #17255a;
}

.pastGraduates_content {
  position: relative;
  display: grid;
  place-items: center;
  gap: 2.5rem;
  padding: 0 1.5rem 3rem;
  max-width: 1200px;
  width: 100%;
  margin: auto;
}

.pastGraduates_list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  width: 100%;
  align-items: stretch;
  border-radius: 30px;
  margin-bottom: 10rem;
  pointer-events: none;
  /* overflow-x: hidden;
	overflow-y: visible; */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.pastGraduates_list::-webkit-scrollbar {
  display: none;
}

.pastGraduates_listItem {
  position: relative;
  height: 15rem;
  pointer-events: all;
}

.pastGraduates_listItem_imageContainer {
  position: relative;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
  background-color: #dfdede;
}

.pastGraduates_list:hover .pastGraduates_listItem_imageContainer::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(273.75deg, #e13f296e -1.55%, #fb7a686e -1.54%, #ec18406e 104.27%);
}

.pastGraduates_listItem_imageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  pointer-events: none;
}

.pastGraduates_listItem_contentContainer {
  position: absolute;
  top: 100%;
  width: 100%;
  visibility: hidden;
  /* transition: .2s ease-in; */
  z-index: 20;
  background-color: #eeeeee;
  padding: 20px 15px;
  display: grid;
  gap: 10px;
  pointer-events: none;
}

.pastGraduates_listItem_contentContainer h3 {
  color: #e13f29;
  font-size: 18px;
  font-weight: 600;
}

.pastGraduates_listItem_contentContainer p {
  font-size: 14px;
  color: #808080;
}

.pastGraduates_listItem_contentContainer span {
  font-size: 16px;
  color: #596275;
}

.pastGraduates_listItem:hover .pastGraduates_listItem_imageContainer::after {
  background: none;
}

.pastGraduates_listItem:hover .pastGraduates_listItem_contentContainer {
  visibility: visible;
  /* top: 100%; */
}

.content_tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  margin: auto;
  background: #eeeeee;
  color: #596275;
  border-radius: 100px;
}

.content_tabs span {
  padding: 0.8rem 2rem;
  cursor: pointer;
  border-radius: 100px;
}

.content_tabs_activeTab {
  background: linear-gradient(273.75deg, #e13f29 -1.55%, #fb7a68 -1.54%, #ec1840 104.27%);
  color: #eeeeee;
}

/* List/Data not found */
.listNotFound {
  display: grid;
  padding: 20px 0;
  place-items: center;
  margin: auto;
}

.listNotFound_imageContainer {
  width: auto;
  height: 20rem;
  transform: translateX(15%);
}

.listNotFound_imageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.listNotFound p {
  font-size: 24px;
  font-weight: 500;
  color: #17255a;
}

/*************** Media Queries ****************/
@media screen and (max-width: 481px) {
  .pastGraduates_listItem {
    height: 20rem;
  }
}
