.wrap {
    width: 100%;
}

.wrap img {
    max-width: 100%;
    object-fit: cover;
}

.mainWrap {
    width: 100%;
    padding: 119px 300px 50px;
}

.inputWrap1 {
    display: flex;
    justify-content: space-between;
    width: 98%;
}

.fluidWrap {
    width: 98%;
}

.inputWrap {
    display: flex;
    justify-content: space-between;
    width: 98%;
    margin: 32px 0;

}

.inputWrap1>div:nth-child(1),
.inputWrap>div:nth-child(1) {
    margin-right: 24px;
}


.submitButton {
    padding: 19.5px 0;
    cursor: pointer;
}

.buttonWrap {
    width: 30%;
}

.submitWrap p {
    margin: 0;
    padding-block: 32px;
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #596275;
}

.submitWrap span {
    text-decoration: underline;
}

@media screen and (max-width: 202px) {
    .mainWrap {
        width: 90%;
        padding: 0 5px;
        margin: auto;
    }

}

@media screen and (max-width: 576px) {
    .mainWrap {
        width: 100%;
        padding: 0 15px !important;
    }

    .mainWrap>div:nth-child(5)>div {
        width: 100%;
    }

    .inputWrap,
    .inputWrap1 {
        flex-wrap: wrap;
        margin: 0;
    }

    .inputWrap>div:nth-child(1),
    .inputWrap1>div:nth-child(1) {
        margin: 15px 0;
    }

    .mainWrap>div:last-child {
        width: 100%;
        margin: 15px auto;
    }

    .submitWrap {
        margin-top: 392px;
    }

    .buttonWrap {
        width: 100%;
        margin-block-end: 30px;
    }
}

@media screen and (max-width: 768px) {
    .mainWrap {
        width: 100%;
        padding: 50px;
    }
}

@media screen and (max-width: 1024px) {
    .mainWrap {
        width: 100%;
        padding: 50px 100px 0;
    }
}