.aboutUsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("../../assets/images/codeville/homeHeroDoodle.png"),
    url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='24' height='24' rx='12' fill='%23FDD19C'/%3E%3C/svg%3E%0A");
  /* url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 22L9.45698e-07 22L1.90735e-06 -9.6165e-07L22 22Z' fill='%23e4e4e4'/%3E%3C/svg%3E%0A"); */
  background-position: right 255px top 52px, left 555px top 53px, left 34px top 198px;
  background-repeat: no-repeat, no-repeat, no-repeat;
}

.aboutUsHeader {
  display: flex;
  flex-direction: column;
  padding: 120px 175px;
  padding-bottom: 31px;
  margin-bottom: 0px;
}

.aboutUsHeader img {
  width: 100%;
  object-fit: cover;
}

.aboutUsHeader h2,
.aboutUsMissionContainer h2,
.aboutUsVisionContainer h2 {
  font-size: 60px;
  line-height: 120%;
  letter-spacing: -1px;
  color: var(--codeville-color-space-cadet);
  margin-bottom: 93px;
}

.aboutUsMissionContainer h2,
.aboutUsVisionContainer h2 {
  font-weight: 600;
  font-size: 48px;
  line-height: 110.19%;
  margin-bottom: 32px;
}

.aboutUsHeader h6 {
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 1px;
  background: -webkit-linear-gradient(273.75deg, #e13f29 -1.55%, #fb7a68 -1.54%, #ec1840 104.27%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.aboutUsHeaderContent {
  column-count: 2;
  column-gap: 29px;
  width: 100%;
}

.aboutUsHeaderContent p,
.aboutUsMissionContainer p,
.visionTextContent p {
  font-weight: 300;
  font-size: 21px;
  line-height: 145%;
  margin-bottom: 16px;
}

.aboutUsMissionContainer p {
  font-size: 32px;
  max-width: 422px;
  min-width: 422px;
}

/* Mission section */

.sectionWrapper {
  background-color: #fafafa;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.aboutUsMissionContainer,
.aboutUsVisionContainer {
  padding: 170px 175px;
  padding-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1440px;
  width: 100%;
}

/* About Us Vision */
.aboutUsVisionContainer {
  justify-content: center;
  flex-direction: column;
  padding: 50px 64px;
  padding-bottom: 50px;
  margin-bottom: 152px;
}

.visionTextContent {
  display: flex;
  align-items: center;
  margin-bottom: 31px;
}

.visionTextContent h2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 233px;
}
.visionTextContent h2 span {
  width: 100%;
  height: 80px;
  background-image: url("data:image/svg+xml,%3Csvg width='97' height='97' viewBox='0 0 97 97' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d_69_2041)'%3E%3Crect x='35.7109' y='5' width='33.5299' height='33.5299' rx='7' transform='rotate(45 35.7109 5)' fill='url(%23paint0_linear_69_2041)'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d_69_2041' x='0.901367' y='0.899414' width='95.6191' height='95.6196' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dx='13' dy='20'/%3E%3CfeGaussianBlur stdDeviation='13.5'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0.258824 0 0 0 0 0.533333 0 0 0 0 0.564706 0 0 0 0.2 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_69_2041'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_69_2041' result='shape'/%3E%3C/filter%3E%3ClinearGradient id='paint0_linear_69_2041' x1='52.4759' y1='5' x2='52.4759' y2='38.5299' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23A0D7EA'/%3E%3Cstop offset='1' stop-color='%2304C28C'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: left 50% top 0%;
}

.visionTextContent p {
  margin-left: 280px;
  max-width: 530px;
  margin-bottom: 0px;
  font-size: 32px;
  line-height: 145%;
}

/* Train Statistics */
.trainStatisticsContainer {
  min-height: 449px;
  width: 100%;
  background: url("../../assets/images/codeville/homeHeroDoodle.png"),
    url("../../assets/images/codeville/trainedStudentsBg.png"),
    linear-gradient(262.1deg, #a0d7ea 15.61%, #04c28c 68.73%);
  background-color: #fafafa;
  background-repeat: no-repeat, repeat, repeat;
  background-position: left 441px top 29px, 100% 100%, 100% 100%;
  box-shadow: 13px 20px 27px rgba(66, 136, 144, 0.2);
  border-radius: 32px;
  position: relative;
  z-index: 2;
}

.trainStatisticsContainer::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 32px;
  background: url("../../assets/images/codeville/graduateFacesBg.png");
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: 50% 100%;
  mix-blend-mode: multiply;
}
.trainStatisticsContainer::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 32px;
  background: url("data:image/svg+xml,%3Csvg width='291' height='172' viewBox='0 0 291 172' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.4' d='M-49 170.5C-49 170.5 -24.4079 3.00708 3.52771 0.309294C31.761 -2.39503 71.1605 42.3064 122.363 54.8824C173.566 67.4583 236.274 47.9153 267.036 65.4071C297.499 82.9054 296.022 137.736 276.047 177.486C256.079 217.534 89.0001 243 58.0001 243C27.0002 243 -49 170.5 -49 170.5Z' fill='%230AAF78'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: left 0px bottom 0px;
  background-size: auto;
  mix-blend-mode: multiply;
}

.trainStatisticsContainer h1 {
  font-weight: 600;
  font-size: 60px;
  line-height: 120%;
  letter-spacing: -1px;
  color: #ffffff;
  max-width: 499px;
  margin-top: 152px;
  margin-left: 109px;
}

@media screen and (max-width: 1150px) {
  .aboutUsHeader,
  .aboutUsMissionContainer {
    padding: 120px 80px;
  }

  .trainStatisticsContainer::after {
    background: none;
  }

  .aboutUsMissionContainer {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .aboutUsMissionImage {
    width: 100%;
  }

  .aboutUsMissionImage img {
    width: 100%;
  }

  .visionTextContent p {
    margin-left: 10%;
  }
}

@media screen and (max-width: 800px) {
  .aboutUsContainer {
    background: none;
  }
  .aboutUsHeader,
  .aboutUsMissionContainer {
    padding: 120px 60px;
  }

  .aboutUsHeaderContent {
    columns: unset;
  }

  .trainStatisticsContainer {
    min-height: 340px;
    background-position: right 90px bottom 8px, 100% 100%, 100% 100%;
  }

  .trainStatisticsContainer h1 {
    font-size: 40px;
    max-width: 456px;
    margin-top: 100px;
    margin-left: 50px;
  }
  .visionTextContent {
    flex-direction: column;
    align-items: flex-start;
  }

  .aboutUsVisionContainer {
    align-items: flex-start;
  }

  .visionTextContent p {
    margin-left: 0px;
  }
}

@media screen and (max-width: 450px) {
  .aboutUsMissionContainer,
  .aboutUsVisionContainer {
    padding: 120px 16px;
    padding-top: 66px;
    padding-bottom: 10px;
  }

  .aboutUsVisionContainer {
    padding-top: 40px;
  }

  .aboutUsMissionContainer h2,
  .aboutUsVisionContainer h2 {
    font-size: 24px;
    margin-bottom: 16px;
  }

  .aboutUsVisionContainer h2 {
    align-items: flex-start;
  }
  .aboutUsVisionContainer h2 span {
    height: 40px;
    width: 40px;
    background-size: 50px 50px;
  }

  .aboutUsHeader h2 {
    font-size: 34px;
    margin-bottom: 32px;
  }

  .aboutUsHeader h6 {
    font-size: 13px;
    margin-bottom: 4px;
  }

  .aboutUsHeader p {
    font-size: 16px;
  }

  .aboutUsMissionContainer p,
  .aboutUsVisionContainer p {
    font-size: 18px;
    min-width: unset;
  }

  .aboutUsHeader {
    padding: 20px 16px;
    padding-top: 42px;
    margin-bottom: 0px;
  }

  .visionTextContent {
    margin-bottom: 42px;
  }

  .aboutUsVisionContainer {
    margin-bottom: 60px;
  }

  .trainStatisticsContainer {
    min-height: 192px;
    background-position: right 72px bottom -10px, 100% 100%, 100% 100%;
  }

  .trainStatisticsContainer::before {
    background-position: left -20px bottom -6px;
    background-size: 50% 50%;
  }

  .trainStatisticsContainer h1 {
    font-size: 32px;
    margin-top: 39px;
    margin-left: 28px;
    max-width: 249px;
  }
}
