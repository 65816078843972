.container {
  position: absolute;
  left: 0;
  right: 0;
  background: var(--genesys-bg-light-grey);
}

.container section:first-of-type {
  margin-top: 30px;
  margin-bottom: 30px;
}

.container section:first-of-type div {
  margin-bottom: 30px;
}

.container section:nth-of-type(2) div {
  padding-top: 10px;
  padding-bottom: 10px;
}

.container section:nth-of-type(2) {
  background: var(--genesys-bg-grey);
  font-family: var(--genesys-font-nunito);
  font-size: 0.625rem;
  color: var(--genesys-black);
}

.container section:nth-of-type(2)>div {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}

.container section:nth-of-type(2) div div:last-of-type {
  order: -1;
}

.container h1,
.container h2 {
  color: var(--genesys-blue);
}

.container h1 {
  margin-bottom: 16px;
  font-size: 1.5rem;
  font-weight: 900;
}

.container h2,
.container p,
.container li {
  font-size: 0.8125rem;
}

.container h2 {
  font-family: var(--genesys-font-nunito);
  font-weight: 700;
  margin-bottom: 16px;
}

.container ul {
  list-style: none;
}

.container p {
  line-height: 1.5625rem;
  color: var(--genesys-grey);
}

.container h2~p {
  font-family: var(--genesys-font-nunito);
}

.container li {
  margin-bottom: 12px;
  font-family: var(--genesys-font-nunito);
  font-weight: 600;
  color: var(--genesys-grey);
}

.container a {
  color: var(--genesys-grey);
  text-decoration: none;
}

.container a:hover {
  text-decoration: underline !important;
  color: var(--genesys-red);
}

.form form {
  display: flex;
  align-items: flex-start;
}

.form form>*:nth-of-type(1) {
  margin-right: 8px;
}

.form>* {
  margin-top: 25px;
}

.form input {
  background: #fff;
}

.copyright span:nth-of-type(2) {
  margin-left: 20px;
}

.links span:not(:last-of-type) {
  margin: 0px 12px;
}

.links svg {
  width: 16px;
  height: 16px;
}

._button {
  padding: 14px 35px;
}

@media screen and (min-width: 768px) {
  .container {
    bottom: 0;
  }

  .container section:first-of-type {
    margin-top: 70px;
  }

  .container section:first-of-type,
  .container section:nth-of-type(2) div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .container section:first-of-type div {
    margin-bottom: 0px;
  }

  .container section:nth-of-type(2) div div:last-of-type {
    order: 1;
  }

  .container h1 {
    font-size: 2.125rem;
  }

  .container h2,
  .container p,
  .container li {
    font-size: 0.9375rem;
  }

  .links svg {
    width: 22px;
    height: 22px;
  }
}