.container {
  padding: 30px;
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(39, 48, 67, 0.08);
  border-radius: 8px;
  height: 282px;
}

.container > div {
  display: flex;
  justify-content: center;
  padding: 15px;
  margin-bottom: 18px;
  background: rgba(23, 37, 90, 0.03);
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.container h1 {
  font-size: 20px;
  font-family: var(--genesys-font-roboto);
  margin-bottom: 24px;
}

.container p {
  font-family: var(--genesys-font-roboto) !important;
  color: var(--genesys-grey);
  font-size: 14px !important;
  line-height: 22px !important;
}
