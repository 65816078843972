.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  padding: 2.3125rem 2rem;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 4px 13px rgba(220, 220, 220, 0.2);
  backdrop-filter: blur(64px);
}

.big-header {
  display: none;
}

.small-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hamburger {
  width: 30px;
  height: 30px;
  margin-top: -7px;
}

.small-header nav {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 100;
}

.small-header ul {
  padding-top: 10rem;
  text-align: center;
  height: 100vh;
  background: #fff;
}

.small-header li {
  margin-bottom: 2.5rem;
}

.small-header li:not(:first-child) {
  width: 90%;
}

.cancel {
  position: absolute;
  right: 30px;
  bottom: 40px;
  width: 30px;
  height: 30px;
}

.container ul {
  list-style: none;
}

.container li {
  font-weight: 500;
}

.container li a {
  text-decoration: none;
  color: var(--learnable-text-color);
}

.container li a:hover {
  color: var(--learnable-dark-orange);
}

.container li:nth-of-type(2) a {
  display: inline-flex;
  align-items: flex-start;
}

li a.active {
  color: var(--learnable-dark-orange);
}

.start-journey {
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
}

.start-journey > * {
  vertical-align: middle;
}

.start-journey span {
  margin-right: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  color: var(--learnable-text-color);
}

.start-journey svg {
  transition: 0.3s;
  transform: rotate(90deg);
}

.start-journey:hover svg {
  transform: rotate(0deg);
}

.start-journey:hover span {
  color: var(--learnable-dark-orange);
}

.start-journey:disabled {
  cursor: not-allowed;
}

.start-journey:disabled span {
  color: var(--genesys-grey);
}

.start-journey:disabled svg > * {
  fill: var(--genesys-grey);
}

.start-journey:disabled:hover span {
  color: var(--genesys-grey);
}

.start-journey:disabled:hover svg > * {
  fill: var(--genesys-grey);
}

@media screen and (min-width: 768px) {
  .small-header li:not(:first-child) {
    width: 100%;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    margin-bottom: 4.5rem;
  }

  .container li {
    display: inline-block;
  }

  .container li:not(:last-of-type) {
    margin-right: 35px;
  }

  .small-header {
    display: none;
  }

  .big-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
