.faqMainContainer {
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("../../assets/images/codeville/homeHeroDoodle.png");
  background-repeat: no-repeat;
  background-position: right 359px top 65px;
}

.faqHeader {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 175px;
  margin-bottom: 64px;
}

.faqHeader h6 {
  color: #56c2df;
  margin-bottom: 8px;
}

.faqHeader h1 {
  font-size: 60px;
  line-height: 120%;
  letter-spacing: -1px;
}

.faqHeader p {
  font-weight: 300;
  font-size: 16px;
  line-height: 145%;
  color: #7c7c7c;
}

@media screen and (max-width: 830px) {
  .faqMainContainer {
    background-position: left 50% top 25px;
  }
  .faqHeader {
    align-items: center;
    padding-left: 0px;
    padding: 20px;
  }

  .faqHeader p,
  .faqHeader h1 {
    text-align: center;
  }
}

@media screen and (max-width: 500px) {
  .faqHeader h1 {
    font-size: 42px;
  }
}
@media screen and (max-width: 450px) {
  .faqMainContainer {
    background: none;
    padding-top: 42px;
  }

  .faqHeader {
    align-items: flex-start;
    padding-left: 16px;
  }
  .faqHeader h1 {
    font-size: 34px;
  }

  .faqHeader p {
    text-align: left;
  }
}
/* FAQ TAB */
.faqAccordionsContainer {
  width: 100%;
  background: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 88px;
  padding-bottom: 120px;
}

.faqAccordionsContainer h4 {
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -1px;
  color: var(--codeville-color-space-cadet);
  margin-bottom: 24px;
}

.faqItem {
  width: 100%;
  max-width: 760px;
  margin-bottom: 10px;
}

.faqItemsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

@media screen and (max-width: 500px) {
  .faqAccordionsContainer {
    padding: 16px 32px;
    padding-bottom: 64px;
  }
}
