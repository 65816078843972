.navigate {
  margin-bottom: 60px;
  text-transform: uppercase;
  color: var(--genesys-red);
  font-family: var(--genesys-font-nunito);
  transition: 0.3s;
}

.navigate span {
  cursor: pointer;
}

.navigate:hover {
  transform: scaleX(1.02);
  text-decoration: underline;
}

.container section {
  margin-bottom: 55px;
}

.container section:nth-of-type(1),
.container section:nth-of-type(2) {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.container section:nth-of-type(1) button {
  display: block;
  margin: 0 auto;
}

.container section:nth-of-type(1) > div:last-of-type,
.container section:nth-of-type(2) > div:last-of-type {
  margin-top: 50px;
  margin-left: auto;
}

.container section:nth-of-type(1) img {
  width: 100%;
}

.container section:nth-of-type(2) > div {
  padding: 80px 0px;
}

.container section:nth-of-type(2) > div:last-of-type {
  padding: 0px 0px;
  margin-top: -40px;
}

.container section:nth-of-type(1) > div p,
.container section:nth-of-type(2) > div p,
.container section:nth-of-type(3) > div p {
  font-family: var(--genesys-font-nunito);
  font-weight: 400;
  font-size: 16px;
  line-height: 27.51px;
  color: var(--genesys-grey);
  margin-bottom: 20px;
}

.approach-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 0px 120px 0px;
}

.approach-card-container > div:first-of-type {
  margin-right: 0px;
  margin-bottom: 120px;
}

.faq-container {
  display: flex;
  flex-direction: column;
}

.faq-container h2 {
  margin-bottom: 0px;
}

.faq-container > div:first-of-type {
  margin-bottom: 60px;
}

.contact-button-a {
  display: none;
  margin-top: 20px;
}

.contact-button-b {
  display: block;
  margin: 40px auto 0 auto;
}

.container section:last-of-type,
.container section:nth-of-type(2) {
  position: relative;
  background: var(--genesys-bg-light-grey);
  padding-bottom: 60px;
}

.container section:last-of-type:before,
.container section:nth-of-type(2)::before {
  content: "";
  position: absolute;
  left: -16px;
  height: 100%;
  width: 90vw;
  background: var(--genesys-bg-light-grey);
}

.container section:last-of-type::after,
.container section:nth-of-type(2)::after {
  content: "";
  position: absolute;
  top: 0;
  right: -16px;
  height: 100%;
  width: 90vw;
  background: var(--genesys-bg-light-grey);
}

.container section:last-of-type > div,
.container section:nth-of-type(2) > div {
  position: relative;
  z-index: 4;
}

.container section:last-of-type > div {
  padding: 80px 0px;
}

@media screen and (min-width: 768px) {
  .navigate {
    margin-bottom: 90px;
  }

  .container section:nth-of-type(1) button {
    display: inline;
  }

  .container section:nth-of-type(1) > div {
    width: 65%;
  }

  .container section:nth-of-type(2) > div:first-of-type {
    width: 60%;
  }

  .container section:nth-of-type(2) > div:last-of-type {
    width: 100%;
  }

  .container section:nth-of-type(3) > div > p {
    width: 58%;
  }

  .approach-card-container > div:first-of-type {
    margin-right: 0px;
    margin-bottom: 120px;
  }

  .container section:last-of-type:before,
  .container section:nth-of-type(2)::before {
    width: 100vw;
  }

  .container section:last-of-type::after,
  .container section:nth-of-type(2)::after {
    width: 100vw;
  }
}

@media screen and (min-width: 1200px) {
  .navigate {
    margin-bottom: 120px;
  }

  .container section:nth-of-type(1) {
    margin-bottom: 120px;
  }

  .container section:nth-of-type(1) > div {
    width: 45%;
  }

  .container section:nth-of-type(2) > div:first-of-type {
    width: 30%;
  }

  .container section:nth-of-type(2) > div:last-of-type {
    width: 45%;
    padding: 80px 0px;
    margin-top: 0px;
  }

  .container section:nth-of-type(1) > div:last-of-type,
  .container section:nth-of-type(2) > div:last-of-type {
    margin-top: 0px;
  }

  .container section:nth-of-type(3) > div > p {
    width: 48%;
  }

  .approach-card-container {
    flex-direction: row;
    justify-content: flex-end;
  }

  .approach-card-container > div:first-of-type {
    margin-right: 20px;
    margin-bottom: 80px;
  }

  .container section:last-of-type:before,
  .container section:nth-of-type(2)::before {
    content: "";
    position: absolute;
    left: -300px;
    height: 100%;
    width: 100vw;
    background: var(--genesys-bg-light-grey);
  }

  .container section:last-of-type::after,
  .container section:nth-of-type(2)::after {
    content: "";
    position: absolute;
    top: 0;
    right: -300px;
    height: 100%;
    width: 100vw;
    background: var(--genesys-bg-light-grey);
  }

  .faq-container {
    display: flex;
    flex-direction: row;
  }

  .faq-container > div:first-of-type {
    width: 50%;
    margin-bottom: 0px;
  }

  .faq-container > div:last-of-type {
    width: 55%;
    margin-left: -40px;
  }

  .contact-button-a {
    display: block;
  }

  .contact-button-b {
    display: none;
  }
}
