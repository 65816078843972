.image-container {
  height: 244px;
  background: #fff;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text-container h1 {
  color: var(--genesys-blue);
  font-weight: 900;
  font-family: var(--genesys-font-roboto);
  font-size: 20px;
  line-height: 22px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.text-container p {
  /* display: none; */
  color: var(--genesys-grey);
  font-weight: 500;
  font-family: var(--genesys-font-roboto);
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 15px;
}

.text-container a {
  color: var(--genesys-red);
  text-decoration: underline;
  font-weight: 600;
  font-family: var(--genesys-font-nunito);
  font-size: 14px;
  line-height: 16px;
}

@media screen and (min-width: 768px) {
  .container {
    width: 55%;
  }

  .text-container h1 {
    font-size: 18px;
  }
}

@media screen and (min-width: 992px) {
  .container {
    width: 45%;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    width: 40%;
  }
}
