.container * {
  font-family: var(--learnable-font) !important;
}

.children {
  margin-top: 180px;
  margin-bottom: 100px;
}

@media screen and (min-width: 768px) {
  .children {
    margin-top: 200px;
    margin-bottom: 700px;
  }
}

@media screen and (min-width: 1200px) {
  .children {
    margin-bottom: 500px;
  }
}
