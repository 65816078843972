.pastProjects {
  padding: 1rem;
  max-width: 1200px;
  margin: auto;
  position: relative;
  background-color: #ffffff;
  display: grid;
  gap: 3rem;
  padding: 60px 10px;
}

.pastProjects_header {
  display: grid;
  gap: 2rem;
  text-align: center;
  max-width: 800px;
  margin: auto;
}

.pastProjects_header span {
  background: linear-gradient(273.75deg, #e13f29 -1.55%, #fb7a68 -1.54%, #ec1840 104.27%);
  background-clip: text;
  -webkit-background-clip: text;
  color: rgb(0 0 0 / 20%);
  font-size: 16px;
  text-transform: uppercase;
}

.pastProjects_header h1 {
  font-size: 48px;
  font-weight: 600;
  color: #17255a;
}

.pastProjects_content {
  display: grid;
  gap: 20px;
}

.pastProjects_list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(250px, 100%), 1fr));
  gap: 20px;
  align-items: stretch;
}

.pastProjects_listItem {
  display: grid;
  border-radius: 20px;
  border: 1px solid #cecdcd;
  overflow: hidden;
}

.pastProjects_listItem:hover{
  box-shadow: 0px 3px 5px 0px #00000024;
}

.pastProjects_listItem_topSection {
  position: relative;
  height: 15rem;
  overflow: hidden;
}

.pastProjects_listItem_topSection::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000070;
}


.pastProjects_listItem_topSection a {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  padding: 0.5rem 1rem;
  font-size: 12px;
  background: #eeeeee;
  color: #596275;
  text-decoration: none;
  border-radius: 2rem;
  position: absolute;
  bottom: 15px;
  left: 15px;
  z-index: 10;
  cursor: pointer;
}

.pastProjects_listItem_coverImage {
  height: 100%;
  overflow: hidden;
}

.pastProjects_listItem_coverImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pastProjects_listItem_bottomSection {
  position: relative;
  padding: 1rem;
  display: flex;
  gap: 20px;
  align-items: center;
}

.pastProjects_listItem_bottomSection h3 {
  color: #f5772f;
  font-size: 16px;
  font-weight: 600;
}

.pastProjects_listItem_bottomSection p {
  font-size: 14px;
  font-weight: 400;
  color: #596275;
}

.pastProjects_listItem_studentImage {
  flex-shrink: 0;
  position: relative;
  width: 4rem;
  height: 4rem;
  border: 2px solid #f5772f;
  border-radius: 50rem;
  overflow: hidden;
}

.pastProjects_listItem_studentImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.pastProjects_loadMore {
  display: inline-block;
  padding: 0.5rem 1rem;
  color: #596275;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  margin: auto;
  border: 1px solid #596275;
  border-radius: 2rem;
}

.content_tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  margin: auto;
  background: #eeeeee;
  color: #596275;
  border-radius: 100px;
}

.content_tabs span {
  padding: 0.8rem 2rem;
  cursor: pointer;
  border-radius: 100px;
}

.content_tabs_activeTab {
  background: linear-gradient(273.75deg, #e13f29 -1.55%, #fb7a68 -1.54%, #ec1840 104.27%);
  color: #eeeeee;
}

/* List/Data not found */
.listNotFound {
  display: grid;
  padding: 20px 0;
  place-items: center;
  margin: auto;
}

.listNotFound_imageContainer {
  width: auto;
  height: 20rem;
  transform: translateX(15%);
}

.listNotFound_imageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.listNotFound p {
  font-size: 24px;
  font-weight: 500;
  color: #17255a;
}
