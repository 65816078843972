.wrap {
  width: 100%;
}

.wrap img {
  max-width: 100%;
}

.mainWrap {
  width: 100%;
  padding: 119px 300px 50px;
}

.inputWrap1 {
  display: flex;
  justify-content: space-between;
  width: 98%;
}

.fluidWrap {
  width: 98%;
}

.inputWrap {
  display: flex;
  justify-content: space-between;
  width: 98%;
  margin: 40px 0;

}

.inputWrap1>div:nth-child(1),
.inputWrap>div:nth-child(1) {
  margin-right: 24px;
}

.buttonWrap {
  margin-block: 32px 136px;
  width: 30%;
}

.submitButton {
  padding: 19px 0;
  font-size: 18px;
  cursor: pointer;
}


@media screen and (max-width: 202px) {
  .mainWrap {
    width: 90%;
    padding: 0 5px;
    margin: auto;
  }

}

@media screen and (max-width: 576px) {
  .mainWrap {
    width: 100%;
    padding: 0 15px !important;
  }

  .mainWrap>div:nth-child(5)>div {
    width: 100%;
  }

  .inputWrap,
  .inputWrap1 {
    flex-wrap: wrap;
    margin: 0;
  }

  .inputWrap>div:nth-child(1),
  .inputWrap1>div:nth-child(1) {
    margin: 15px 0;
  }

  .mainWrap>div:last-child {
    width: 100%;
    margin: 15px auto;
  }

  .buttonWrap {
    margin-top: 92px;
  }
}

@media screen and (max-width: 768px) {
  .mainWrap {
    width: 100%;
    padding: 50px;
  }
}

@media screen and (max-width: 1024px) {
  .mainWrap {
    width: 100%;
    padding: 50px 100px 0;
  }
}