.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  padding: 2.3125rem 2rem;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 4px 13px rgba(220, 220, 220, 0.2);
  backdrop-filter: blur(64px);
}

.big-header {
  display: none;
}



.big-header img {
  height: 42px;
  width: 182.46px;
  object-fit: contain;
}

.small-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hamburger {
  width: 30px;
  height: 30px;
  margin-top: -7px;
}

.drop-down-container {
  position: relative;
}

.drop-down-container:hover {
  color: var(--genesys-red);
  text-decoration: underline;
}

.drop-down-container svg {
  margin-left: 6px;
  position: absolute;
  margin-top: -1px;
}

.mobile-dropdown-icon {
  position: absolute;
  margin-top: -1px;
}

.drop-down {
  position: absolute;
  top: 40px;
  backdrop-filter: blur(50px);
  background: linear-gradient(128.04deg, rgba(250, 92, 3, 0.08) -4.3%, rgba(250, 92, 3, 0.02) 103.42%),
    linear-gradient(128.26deg, rgba(255, 255, 255, 0.8) 21.81%, rgba(255, 255, 255, 0.2) 100%);
  padding: 35px;
  z-index: 5;
}

.drop-down li {
  margin-bottom: 20px;
}

.drop-down a:hover,
.drop-down-small a:hover {
  text-decoration: underline;
}

.small-header img {
  width: 134px;
  height: 30.84px;
}

.small-header nav {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(158.05deg, rgba(245, 119, 47, 0.067) 27.36%, rgba(225, 63, 41, 0.014) 95.18%),
    linear-gradient(128.21deg, rgba(255, 255, 255, 0.32) -2.96%, rgba(255, 255, 255, 0.08) 90.61%);
  backdrop-filter: blur(50px);
  background: linear-gradient(128.04deg, rgba(250, 92, 3, 0.08) -4.3%, rgba(250, 92, 3, 0.02) 103.42%),
    linear-gradient(128.26deg, rgba(255, 255, 255, 0.8) 21.81%, rgba(255, 255, 255, 0.2) 100%);
  z-index: 2;
}

.small-header ul {
  padding-top: 4rem;
  padding-left: 2rem;
  height: 100vh;
  background: #fff;
}

.small-header li {
  margin-bottom: 2.5rem;
}

.small-header li:nth-of-type(2)>div:first-of-type {
  display: inline-flex;
  align-items: flex-start;
  cursor: pointer;
}

.small-header li:nth-of-type(2) svg {
  margin-left: 6px;
}

.drop-down-small ul {
  padding-top: 2rem;
  padding-left: 3rem;
  font-size: 15px;
  height: 25vh;
}

.drop-down-small li {
  margin-bottom: 28px;
}

.cancel {
  position: absolute;
  right: 30px;
  bottom: 40px;
  width: 30px;
  height: 30px;
}

.container ul {
  list-style: none;
}

.container li {
  font-family: var(--genesys-font-nunito);
  font-weight: 600;
}

.container li a {
  text-decoration: none;
  color: var(--genesys-black);
}

.container li a:hover {
  color: var(--genesys-red);
  text-decoration: underline;
}

li a.active {
  color: var(--genesys-red);
  text-decoration: underline;
}

@media screen and (min-width: 768px) {
  .container {
    margin-bottom: 4.5rem;
  }

  .small-header {
    display: none;
  }

  .big-header {
    display: flex;
    padding: 0px 60px;
    justify-content: space-between;
    align-items: center;
  }

  .container li {
    display: inline-block;
  }

  .container li:nth-of-type(2) {
    display: inline-flex;
    align-items: flex-start;
    cursor: pointer;
  }

  .container li:not(:last-of-type) {
    margin-right: 1.25rem;
  }
}

@media screen and (min-width: 1200px) {
  .container li:not(:last-of-type) {
    margin-right: 35px;
  }
}