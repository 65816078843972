.container {
  padding-top: 3rem;
  position: relative;
  max-width: var(--learnable-max-width);
  margin: 0 auto;
}

.circle,
.spiral {
  display: none;
  position: absolute;
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  margin-top: 3rem;
}

.grid-card {
  text-align: center;
  border: 1px solid var(--learnbale-border-color);
  border-radius: 1rem;
  padding: 2rem 1rem;
  transition: 1s;
}

.grid-card:hover {
  transform: rotate(-2deg);
  background: var(--learnable-dark-orange);
}

.grid-card:hover .grid-card-link {
  color: var(--genesys-white);
}

.grid-card:hover .grid-card-icon {
  background: var(--genesys-white);
}

.grid-card-icon {
  display: inline-block;
  background: var(--learnable-gray);
  border-radius: 50%;
  padding: 0.6rem;
  margin-bottom: 2.5rem;
}

.grid-card-icon svg {
  vertical-align: middle;
}

.grid-card-header {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0.4rem;
  color: var(--learnable-dark-blue);
}

.grid-card-paragraph {
  color: var(--learnable-text-color);
  margin-bottom: 1rem;
  font-weight: 300;
  line-height: 1.5rem;
}

.grid-card-link {
  color: var(--learnable-dark-orange);
  text-decoration: none;
}

.grid-card-link:hover {
  text-decoration: underline;
}

.grid-card button {
  background: var(--learnable-dark-orange);
}

.grid-card button span {
  display: inline-block;
  margin-right: 0.8rem;
}

.grid-card button svg * {
  stroke: var(--genesys-white);
  fill: var(--genesys-white);
}

@media screen and (min-width: 768px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-start;
    margin-top: 4rem;
    gap: 2.5rem;
  }

  .grid-card-icon {
    padding: 1rem;
  }

  .grid-card-header {
    font-size: 1.5rem;
  }

  .grid-card-paragraph,
  .grid-card-link {
    font-size: 1.19rem;
    line-height: 1.875rem;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    padding-top: 8rem;
  }

  .circle,
  .spiral {
    display: block;
  }

  .circle {
    top: 0;
    left: 0;
  }

  .spiral {
    top: 60px;
    right: 0;
  }

  .grid {
    grid-template-columns: repeat(3, 1fr);
    margin-top: 10rem;
    padding: 0rem 1rem;
  }
}
