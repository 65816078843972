.container {
  position: relative;
  width: 95%;
  height: 260px;
  margin-bottom: 80px;
}

.container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.text-container {
  position: relative;
  bottom: 80px;
  margin: 0 auto;
  padding: 15px;
  backdrop-filter: blur(50px);
  box-shadow: 0px 2px 8px 0px rgba(238, 238, 238, 0.2);
  background: linear-gradient(102.42deg, rgba(255, 255, 255, 0.48) 0%, rgba(255, 255, 255, 0.12) 100.66%);
  width: 260px;
}

.text-container h1 {
  color: var(--genesys-blue);
  font-family: var(--genesys-font-roboto);
  font-size: 18px;
  font-weight: 900;
  line-height: 22px;
  letter-spacing: 0.5px;
  margin-bottom: 10px;
}

.text-container > p {
  color: var(--genesys-grey);
  font-family: var(--genesys-font-nunito);
  font-size: 13px !important;
  font-weight: 400;
  line-height: 21px !important;
  letter-spacing: 0.5px;
}

@media screen and (min-width: 767px) {
  .container {
    width: 410px;
  }

  .text-container {
    width: 300px;
    margin: 0 auto;
  }

  .text-container h1 {
    font-size: 20px;
  }

  .text-container p {
    font-size: 13px !important;
  }
}

@media screen and (min-width: 992px) {
  .text-container {
    width: 340px;
  }
}

@media screen and (min-width: 1200px) {
  /* .text-container {
  } */
}
