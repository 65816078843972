.InvestmentWrapper {
    width: 100%;
    height: auto;
    background-color: #f2f2f2;
}
.investmentImg {
    width: 100%;
    height: 100%;
}

.investmentContainer {
    width: 100%;
    margin: 0px 0px 70px;
  }
  .investmentRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin: auto;
  }
  .invContainerFluid {
    max-width: 100%;
    font-style: normal;
    padding: 100px 0;
}
.invCol {
    width: 50%; 
    margin: 0px 100px;
}
.invFullGridCardParagraph1{
    margin-top: 36px;
    font-family: Nunito;
    font-size: 1.0625rem; /* 17px */
    line-height: 28px;
    letter-spacing: 0.5px;
    color: #7D7D7D;
    font-weight: 400;
}
.invHeadingWrapper > h6 {
  font-family: Nunito;
  font-weight: 600;
  font-size: 0.9375rem; /*15px */
  line-height: 110.19%;
  letter-spacing: 1.25px;
  color: #C4C4C4;
  margin-bottom: 16px;
}
.invHeadingWrapper > h4 {
  font-family: Roboto;
  font-weight: 900;
  font-size: 3.75rem; /* 60px */
  line-height: 110.19%;
  letter-spacing: -0.5px;
  color: #17255A;
}
@media screen and (max-width: 429px) {
    .InvestmentWrapper {
      width: 114%;
      height: inherit;
      margin: 0px -9%;
    }
    .investmentContainer {
      margin: 50px 0px;
    }
   
}

@media screen and (max-width: 769px) {
  .invContainerFluid {
    padding: 64px 0px 14px;
}
.invCol {
  width: 100%;
  margin: 0px;
}
.invHeadingWrapper h6 {
  font-size: 13px;
}
.invFullGridCardParagraph1  {
  width: 90%;
}
  .InvestmentWrapper {
    width: 114%;
    height: inherit;
    margin: 0px -9%;
  }
  .invHeadingWrapper > h4 {
    font-weight: 900;
    font-size: 34px;
  }
  .investmentContainer {
    margin: 50px 0px;
  }
  .investmentRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin: auto;
  }
}