.footerContainer {
  min-height: 480px;
  background-color: var(--codeville-footer-bg);
}

.footerContainer * {
  font-family: var(--codeville-font);
}

/* Footer links and info */
.footerInfoAndLinksContainer {
  display: flex;
  max-width: 1200px;
  margin: auto;
  padding: 1.5rem;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
}

.footerInfoContainer {
  display: flex;
  flex-direction: column;
  max-width: 417px;
  margin-right: 50px;
}

.footerInfoContainer h2 {
  font-weight: 700;
  margin-bottom: 1.5rem;
  font-size: 32px;
  line-height: 110.19%;
  color: var(--codeville-color-space-cadet);
}

.footerInfoContainer p {
  color: var(--codeville-text-color);
  margin-bottom: 34px;
  line-height: 26px;
  letter-spacing: 0.15px;
}

.infoSubscribeContainer {
  display: flex;
}

.subscribeInput {
  margin-right: 7px;
  min-width: 300px;
}

/* Footer Links */

.footerLinksAndContactContainer {
  display: flex;
  flex-wrap: wrap;
}

.footerLinksContainer {
  padding-top: 39px;
  flex-wrap: wrap;
  display: flex;
}

.footerLinksGroupContainer {
  margin-right: 80px;
  min-width: 150px;
}

.footerLinksGroupContainer:first-of-type {
  margin-right: 123px;
}

.footerLinksGroupContainer h3,
.getInTouch h3 {
  color: var(--codeville-color-space-cadet);
  margin-bottom: 16px;
}

.footerLinkItem {
  margin-bottom: 16px;
}

.footerLinkItem a,
.getInTouch p {
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  color: var(--codeville-text-color);
  line-height: 145%;
}

.footerLinkItem a {
  text-decoration: none;
  text-transform: capitalize;
}

.footerLinkItem a:hover {
  color: var(--codeville-link-hover);
  text-decoration: underline;
}

.footerLinksGroup {
  list-style-type: none;
}

.getInTouch {
  padding-top: 39px;
  max-width: 195px;
  min-width: 195px;
}

/* Footer social links */
.footerSocialsContainer {
  background-color: #e3e3e3;
  margin-top: 90px;
  width: 100%;
}

.footerSocialsWrapper{
  max-width: 1200px;
  margin: auto;
  padding: 1.5rem;
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
}

.footerCopy {
  display: flex;
  align-items: center;
}

.footerCopy p {
  font-family: Nunito;
  font-size: 10px;
  line-height: 110.19%;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #273043;
}

.footerCopy p:first-of-type {
  margin-right: 24px;
}

.socialLinksContainer {
  display: flex;
  align-items: center;
  margin-left: 50px;
}

.socialLinksContainer a:not(:last-of-type) {
  margin-right: 24px;
}

@media screen and (max-width: 1270px) {
  .footerLinksGroupContainer {
    margin-bottom: 40px;
  }
  .getInTouch {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 685px) {
  .footerCopy {
    width: 100%;
    justify-content: center;
  }

  .socialLinksContainer {
    width: 100%;
    margin-left: 0px;
    margin-bottom: 20px;
    justify-content: center;
  }
}

@media screen and (max-width: 500px) {
  .footerSocialsContainer {
    margin-top: 0px;
  }
  .subscribeInput {
    max-width: 225px;
    min-width: 200px;
  }
}

@media screen and (max-width: 350px) {
  .infoSubscribeContainer {
    flex-direction: column;
  }
}
