.container {
  padding: 3rem 0rem;
  position: relative;
  max-width: var(--learnable-max-width);
  margin: 0 auto;
}

.spiral {
  display: none;
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  margin-top: 4rem;
}

.grid-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid var(--learnable-gray);
  border-radius: 1rem;
  transition: 0.3s;
}

.grid-card:hover {
  background: var(--learnable-light-orange);
}

.grid-card:nth-of-type(1) {
  border-color: var(--genesys-blue);
}

.grid-card:nth-of-type(2) {
  border-color: var(--genesys-red);
}

.grid-card:nth-of-type(3) {
  border-color: var(--learnable-green);
}

.grid-card:nth-of-type(4) {
  border-color: var(--learnable-yellow);
}

.grid-card-text-container {
  padding: 1rem;
}

.card-icon {
  display: inline-block;
  margin-bottom: 1.5rem;
}

.grid-card-text-container h3 {
  font-size: 1.125rem;
  margin-bottom: 0.7rem;
  color: var(--learnable-dark-blue);
}

.grid-card-text-container p {
  color: var(--learnable-text-color);
  margin-bottom: 0.4rem;
  line-height: 1.5rem;
  font-weight: 300;
}

.grid-card-image-container {
  position: relative;
  width: 100%;
  height: 15rem;
  background: var(--learnable-gray);
  background-position: center;
  background-size: cover;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.grid-card-image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

@media screen and (min-width: 768px) {
  .container {
    padding: 4rem 0rem;
  }

  .grid-card {
    width: 500px;
    margin: 0 auto;
  }

  .grid-card-text-container h3 {
    font-size: 1.5rem;
  }

  .grid-card-text-container p {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }
}

@media screen and (min-width: 992px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .grid-card {
    width: 100%;
    margin: 0 0;
  }
}

@media screen and (min-width: 1200px) {
  .spiral {
    display: block;
    position: absolute;
    top: -50px;
    right: 0;
  }

  .grid {
    padding: 4rem 8rem;
  }

  .grid-card-text-container {
    padding: 2rem;
  }
}
