._wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 144px;
    padding-inline: 91px;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 3000;
    background: #FFF;
    border-bottom: 1px solid #F5F5F5;
}

._ul {
    display: flex;
    list-style: none;
}

._ul li a {
    text-decoration: none;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 1.25px;
    margin-inline-end: 44px;
    color: #273043;
}

._button a {
    text-decoration: none;
    color: #273043;
}

._button {
    background: #FFF;
    border: 1px solid #111920;
    border-radius: 210px;
    padding: 15px 28px;
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    display: flex;
    align-items: center;
    color: #111920;
    cursor: pointer;
    letter-spacing: 1.25px;
}

._button svg {
    margin-inline-start: 10px;
}

@media screen and (max-width: 1024px) {
    ._button {
        padding: 5px 15px;
        font-size: 12px;
    }

    .arrow {
        display: none;
    }
}

@media screen and (max-width: 768px) {

    ._wrap {
        display: none;
    }

    ._mobileUl {
        list-style: none;
    }

    ._mobileUl li {
        padding-block: 12px;
        border-block: solid 1px #F5F5F5;
    }

    ._mobileUl li a {
        text-decoration: none;
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #273043;
        padding-inline: 14px;
    }

    .mobileHeader {
        display: flex;
        flex-direction: column;
        border: 1px solid #F5F5F5;
        transition: all 0.3s ease-in-out;

        position: fixed;
        background-color: #FFF;
        width: 100%;
        z-index: 100;
    }

    .mobileLogo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 17px;
    }
}

@media screen and (max-width: 425px) {
    .mobileHeader {
        z-index: 3000;
    }
}