.sectionWrapper {
  background-color: #fafafa;
}

.sectionWrapper .programHeader {
  background-color: #fff;
}

.programContainer {
  padding-top: 120px;
}

.programHeader {
  min-height: 576px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-image: url("../../assets/images/codeville/homeHeroDoodle.png");
  background-repeat: no-repeat;
  background-position: right 38.68% bottom 17.88%;
}

.programHeader::after {
  position: absolute;
  content: "";
  /* background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 22L9.45698e-07 22L1.90735e-06 -9.6165e-07L22 22Z' fill='%239DE3EB'/%3E%3C/svg%3E%0A"); */
  height: 22px;
  width: 22px;
  left: 34px;
  top: 0px;
  border-radius: 0px;
}

.programHeader h1 {
  font-weight: 600;
  font-size: 60px;
  line-height: 120%;
  letter-spacing: -1px;
  margin-bottom: 24px;
  text-align: center;
}

.programHeader p {
  max-width: 784px;
  font-weight: 300;
  font-size: 18px;
  line-height: 145%;
  text-align: center;
}

/* About section */
.programAbout {
  display: grid;
  width: 100%;
  gap: 50px;
  max-width: 1200px;
  margin: auto;
  padding: 4rem 1.5rem;
}
.programAboutWrapper {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: start;
  gap: 30px;
}

section.programAbout:last-of-type {
  padding-bottom: 127px;
}

.programAboutImage {
  width: 100%;
  position: sticky;
  top: 7rem;
}

.programAboutImage img {
  width: 100%;
}

.programAboutTextContent {
  display: grid;
  gap: 10px;
  max-width: 476px;
}

.programAboutTextContent h6 {
  line-height: 1.5;
  background: -webkit-linear-gradient(273.75deg, #e13f29 -1.55%, #fb7a68 -1.54%, #ec1840 104.27%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
}

.programAboutTextContent p {
  /* text-align: justify; */
  white-space: pre-line;
  font-size: 21px;
  line-height: 1.7;
}

.virtualProgramMoreAboutA p span {
  font-weight: 600;
  color: var(--codeville-color-space-cadet);
}

/* Virtual Section */

.virtualProgramMoreAbout {
  padding: 80px 170px 0px 170px;
}

section.virtualProgramMoreAbout:last-of-type {
  padding-bottom: 127px;
}

.virtualProgramMoreAbout h2,
.virtualProgramMoreAbout h6 {
  text-align: left !important;
}

.virtualProgramMoreAbout h6 {
  margin-bottom: 12px;
  text-align: center;
}

.virtualProgramMoreAboutA h6 {
  color: #f5772f;
}

.virtualProgramMoreAboutA {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.virtualProgramMoreAboutA p {
  font-size: 21px;
  line-height: 145%;
}

.virtualProgramMoreAboutB {
  margin-top: 100px;
}

.virtualProgramMoreAboutB h6 {
  color: #ec1840;
}

.virtualProgramMoreAboutB > div:last-of-type {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  margin-top: 50px;
}

.virtualMustHaves {
  margin-top: 80px;
  margin-bottom: 100px;
}

.virtualMustHaves h6 {
  color: #56c2df;
  margin-bottom: 12px;
  text-align: center;
}

.virtualMustHaves h2,
.virtualProgramMoreAbout h2 {
  font-weight: 600;
  font-size: 48px;
  line-height: 110%;
  color: var(--codeville-color-space-cadet);
  margin-bottom: 71px;
  text-align: center;
}

.virtualMustHaves > div {
  display: grid;
  grid-template-columns: repeat(3, 0.3fr);
  justify-content: center;
}

.virtualMustHaveItem {
  text-align: center;
  display: inline-block;
}

.virtualMustHaveItem p {
  margin-top: 13px;
  color: #596275;
}

/* FAQ Section */
.programFAQsContainer {
  background-color: #fafafa;
  position: relative;
}

.programFAQsWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  gap: 20px;
  margin: auto;
  padding: 4rem 1.5rem;
  height: fit-content;
  max-width: 1200px;
  width: 100%;
}

.programFAQIntro {
  display: grid;
}

.programFAQIntro h6 {
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 16px;
  color: #c4c4c4;
}

.programFAQIntro h2,
.programAboutTextContent h2 {
  font-weight: 600;
  font-size: 48px;
  line-height: 1.5;
  color: var(--codeville-color-space-cadet);
  max-width: 305px;
  min-width: 305px;
}

.virtualProgramMoreAboutA h2 {
  width: 50%;
}

.programFAQs {
  grid-column: span 2;
  display: grid;
  gap: 1rem;
}

.programFAQ {
  width: 100%;
  padding: 1rem;
}

/* Programme Tutorials */
.programme_tutorials {
  position: relative;
  display: grid;
  gap: 30px;
  margin: auto;
  padding: 3rem 1.5rem;
  width: 100%;
  max-width: 1200px;
}

.programme_tutorialHead {
  display: grid;
  gap: 10px;
}

.programme_tutorialHead span {
  background: linear-gradient(273.75deg, #e13f29 -1.55%, #fb7a68 -1.54%, #ec1840 104.27%);
  background-clip: text;
  color: transparent;
  font-size: 16px;
  text-transform: uppercase;
}

.programme_tutorialHead h1 {
  font-size: 32px;
  font-weight: 600;
  color: #17255a;
  text-transform: capitalize;
}

.programme_tutorialBody {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
}

.programme_tutorialCard {
  display: grid;
  place-content: start;
  gap: 20px;
  padding: 30px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 20px;
  overflow: hidden;
  transition: 0.6s ease-in-out;
}

.programme_tutorialCard_imageContainer {
  width: 5rem;
  height: 5rem;
  transform: rotate(0deg);
  transition: 0.6s ease-in-out;
}

.programme_tutorialCard_imageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.programme_tutorialCard_content {
  display: grid;
  gap: 20px;
  max-width: 250px;
  width: 100%;
}

.programme_tutorialCard_content h2 {
  font-size: 20px;
  color: #17255a;
}

.programme_tutorialCard_content ul {
  display: grid;
  gap: 20px;
  list-style: outside;
  padding: 0 20px;
  font-size: 16px;
  font-weight: 400;
  color: #596275;
  line-height: 1.5;
}

.programme_tutorialCard:hover {
  box-shadow: 0 3px 5px 0 #00000024;
}

.programme_tutorialCard:hover .programme_tutorialCard_imageContainer {
  transform: rotate(-90deg);
}

/* Programme Concepts */
.programme_concepts {
  position: relative;
  display: grid;
  gap: 30px;
  margin: auto;
  padding: 3rem 1.5rem;
  width: 100%;
  max-width: 1200px;
}

.programme_conceptHead {
  display: grid;
  gap: 10px;
}

.programme_conceptHead span {
  background: linear-gradient(273.75deg, #e13f29 -1.55%, #fb7a68 -1.54%, #ec1840 104.27%);
  background-clip: text;
  color: transparent;
  font-size: 16px;
  text-transform: uppercase;
}

.programme_conceptHead h1 {
  font-size: 32px;
  font-weight: 600;
  color: #17255a;
  text-transform: capitalize;
}

.programme_conceptBody {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(2, 1fr);
}

.programme_conceptCard,
.programme_conceptCard_span2 {
  display: grid;
  place-content: start;
  gap: 20px;
  padding: 30px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 20px;
  overflow: hidden;
  transition: 0.6s ease-in-out;
}

.programme_conceptCard_span2 {
  grid-column: span 2 / span 2;
}

.programme_conceptCard_imageContainer {
  width: 5rem;
  height: 5rem;
  transform: rotate(0deg);
  transition: 0.6s ease-in-out;
}

.programme_conceptCard_imageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.programme_conceptCard_content {
  display: grid;
  gap: 20px;
  width: 100%;
}

.programme_conceptCard_content h2 {
  font-size: 20px;
  color: #17255a;
}

.programme_conceptCard_content ul {
  display: grid;
  gap: 20px;
  list-style: outside;
  padding: 0 20px;
  font-size: 16px;
  font-weight: 400;
  color: #596275;
  line-height: 1.5;
}

.programme_conceptCard:hover,
.programme_conceptCard_span2:hover {
  box-shadow: 0 3px 5px 0 #00000024;
}

.programme_conceptCard:hover .programme_conceptCard_imageContainer,
.programme_conceptCard_span2:hover .programme_conceptCard_imageContainer {
  transform: rotate(-90deg);
}

/* Programme Requirements */
.programme_requirements {
  position: relative;
  padding: 3rem 1.5rem;
  width: 100%;
  max-width: 940px;
  margin: auto;
  display: flex;
  align-items: start;
  justify-content: space-between;
}

.programme_requirementImagesWrapper {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.programme_requirementImage {
  width: auto;
  height: 20rem;
  overflow: hidden;
  position: relative;
  margin-right: 40px;
}

.programme_requirementImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.programme_requirementImage:nth-child(1) {
  right: -3rem;
}

.programme_requirementImage:nth-child(2) {
  top: -3rem;
  left: -3rem;
}

.programme_requirementWrapper {
  display: grid;
  gap: 30px;
}

.programme_requirementHead {
  display: grid;
  gap: 10px;
}

.programme_requirementHead span {
  background: linear-gradient(273.75deg, #e13f29 -1.55%, #fb7a68 -1.54%, #ec1840 104.27%);
  background-clip: text;
  color: transparent;
  font-size: 16px;
  text-transform: uppercase;
}

.programme_requirementHead h1 {
  font-size: 32px;
  font-weight: 600;
  color: #17255a;
  text-transform: capitalize;
}

.programme_requirementBody {
  display: grid;
  gap: 1.5rem;
}

.programme_requirementItem {
  display: flex;
  align-items: center;
  gap: 20px;
}

@media screen and (max-width: 1150px) {
  .programAbout {
    flex-direction: column;
    align-items: flex-start;
  }

  .programAboutImage {
    margin-right: 0px;
    margin-bottom: 40px;
  }

  .programAboutTextContent {
    max-width: unset;
  }

  .virtualProgramMoreAboutB > div:last-of-type {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 900px) {
  .programHeader::after {
    left: 34px;
    top: -52px;
  }

  .programHeader {
    padding: 16px;
  }

  .virtualProgramMoreAboutA {
    grid-template-columns: 1fr;
  }

  .virtualProgramMoreAboutA h2 {
    width: 100%;
  }

  .virtualMustHaves {
    margin-bottom: 60px;
  }

  .programFAQIntro h2 {
    width: 100%;
    max-width: unset;
  }
}

@media screen and (max-width: 740px) {
  .programAboutWrapper {
    display: grid;
    grid-template-columns: auto;
  }
  .programAboutImage {
    position: relative;
    top: auto;
  }
  .programme_conceptBody {
    grid-template-columns: auto;
  }
  .programme_conceptBody > * {
    grid-column: auto;
  }
  .programFAQsWrapper {
    grid-template-columns: auto;
  }
  .programFAQIntro {
    place-items: center;
    text-align: center;
  }
  .programFAQs {
    grid-column: auto;
  }
  .programme_requirementImagesWrapper {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .programHeader h1 {
    font-size: 34px;
    margin-bottom: 24px;
  }
  .programHeader p,
  .programAbout p,
  .virtualProgramMoreAboutA p {
    font-size: 16px;
  }

  .programFAQsContainer,
  .programAbout,
  .virtualProgramMoreAbout {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 38px;
  }

  section.programAbout:last-of-type {
    padding-bottom: 64px;
  }

  section.virtualProgramMoreAbout:last-of-type {
    padding-bottom: 64px;
  }

  .virtualProgramMoreAboutB {
    margin-top: 60px;
  }

  .virtualMustHaves {
    margin-top: 50px;
    margin-bottom: 30px;
  }

  .virtualMustHaves h2,
  .virtualProgramMoreAbout h2 {
    font-size: 24px;
  }

  .virtualMustHaves h6,
  .virtualProgramMoreAbout h6 {
    font-size: 13px;
  }

  .virtualProgramMoreAboutB > div:last-of-type {
    grid-template-columns: 1fr;
  }

  .virtualMustHaves > div {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 40px;
    justify-content: center;
  }

  .programFAQIntro h2,
  .programAbout h2,
  .virtualProgramMoreAboutA h2 {
    font-size: 24px;
  }

  .programFAQIntro h6,
  .programAbout h6,
  .virtualProgramMoreAbout h6 {
    font-size: 13px;
    margin-bottom: 4px;
  }
}
