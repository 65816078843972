.wrapper {
  padding: 30px 0;
  font-style: normal;
  /* background-color: aqua; */
}
.wrapper h6 {
    color: var(--genesys-light-grey);
    font-family: var(--genesys-font-nunito);
    font-size: 0.8125rem;
    font-weight: 600;
    margin-bottom: 16px;
    letter-spacing: 0.4px;
    /* background-color: brown; */
  }
  .wrapper h2 {
    font-size: 2.125rem;
    font-weight: 900;
    margin-bottom: 30px;
    color: var(--genesys-blue);
    font-family: var(--genesys-font-roboto);
    line-height: 110.19%;
    letter-spacing: 0.25px;
  }
  .wrapper p {
    color: var(--genesys-grey);
    font-size: 1rem !important;
    margin-bottom: 20px;
    line-height: 26px !important;
    font-family: var(--genesys-font-roboto);
    letter-spacing: 0.15px;
    margin: 0px;
  }

  @media only screen and (min-width: 768px) {
    .wrapper h2 {
      font-family: var(--genesys-font-roboto);
      color: var(--genesys-blue);
      font-weight: 900;
      font-size: 3.75rem;
      line-height: 110.19%;
      letter-spacing: -0.5px;
      text-transform: capitalize;
    }
    .wrapper h6 {
      font-family: var(--genesys-font-nunito);
      color: var(--genesys-light-grey);
      font-weight: 600;
      font-size: 0.9375rem;
      line-height: 110.19%;
      letter-spacing: 1.25px;
    }
    .wrapper p {
      font-family: var(--genesys-font-roboto);
    font-size: 1.5rem;
    line-height: 124.69%;                       
    }
    .wrapper {
      margin: 0px 70px 40px;
    }

    .wrapper > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .wrapper > div div:nth-of-type(2) {
      width: 35%;
    }
    .wrapper > div div:nth-of-type(1) {
      width: 65%;
    }
  }
  
  @media only screen and (min-width: 429px) and (max-width: 932px) {
    .wrapper h2 {
      font-size: 2.75rem;
    }
}
  