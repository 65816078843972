.textareaWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.textareaLabelElement {
    font-family: 'Segoe UI', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #273043;
    margin-block-end: 12px;
}

.textareaElement {
    font-family: 'Segoe UI', sans-serif;
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    outline: none;
    color: #8D96B4;
    box-sizing: border-box;
    padding: 13px 20px;
    background: #FFFFFF;
    border: 1px solid #DCDFE8;
    border-radius: 8px;
}

.errorTextElement {
    color: #FF8170;
    font-size: 10px;
    margin-top: 5px;
}