.content_tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  margin: auto;
  background: #eeeeee;
  color: #596275;
  border-radius: 100px;
}

.content_tabs span {
  padding: 0.8rem 2rem;
  cursor: pointer;
  border-radius: 100px;
}

.content_tabs_activeTab {
  background: linear-gradient(273.75deg, #e13f29 -1.55%, #fb7a68 -1.54%, #ec1840 104.27%);
  color: #eeeeee;
}

.bannerContainer{
  display: block;
  background: linear-gradient(90deg, #E1402A 0%, #EAC435 100%);
  overflow: hidden;
}

.bannerWrapper{
  display: block;
  max-width: 1200px;
  width: 100%;
  padding: 4rem 1.5rem;
  margin: auto;
}

.bannerBody{
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 3rem;
  position: relative;
}

.bannerBody::before{
  content: "";
  width: 130px;
  height: 130px;
  background: linear-gradient(273.75deg, #E13F29 -1.55%, #FB7A68 -1.54%, #EC1840 104.27%);
  border-radius: 200%;
  position: absolute;
  bottom: -50px;
  left: -50px;
}

.bannerBody::after{
  content: "";
  width: 75px;
  height: 75px;
  background: linear-gradient(273.75deg, #E13F29 -1.55%, #FB7A68 -1.54%, #EC1840 104.27%);
  border-radius: 200%;
  position: absolute;
  top: 10px;
  right: -50px;
}

.bannerBody_wrapper{
  position: relative;
  display: grid;
  place-items: center;
  gap: 1rem;
  margin: auto;
  max-width: 700px;
  text-align: center;
  z-index: 10;
}

.bannerBody_wrapper h4{
  background: linear-gradient(273.75deg, #E13F29 -1.55%, #FB7A68 -1.54%, #EC1840 104.27%);
  background-clip: text;
  color: transparent;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.3;
  text-transform: uppercase;
}

.bannerBody_wrapper p{
  color: #17255A;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.3;
}

.bannerBody_wrapper a{
  display: inline-flex;
  align-items: center;
  gap: 10px;
  padding: 1rem 1.5rem;
  margin: auto;
  border: 1px solid;
  border-radius: 30px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  color: #596275;
  cursor: pointer;  
}

.bannerBody_wrapper a:hover{
  color: rgba(239, 41, 71, 0.68);
  box-shadow: 0px 4px 10px rgba(239, 41, 71, 0.68);
}
