.header-text,
.header-paragraph {
  text-align: center;
}

.header-text {
  font-size: 2.375rem;
  font-weight: 600;
  margin-bottom: 0.8rem;
  color: var(--learnable-dark-blue);
}

.header-paragraph {
  color: var(--learnable-text-color);
  font-weight: 300;
  line-height: 1.5rem;
}

@media screen and (min-width: 768px) {
  .header-text {
    font-size: 2.55rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  .header-paragraph {
    font-size: 1.15rem;
    line-height: 1.875rem;
  }
}

@media screen and (min-width: 1200px) {
  .header-text {
    font-size: 3rem;
  }

  .header-paragraph {
    font-size: 1.25rem;
  }
}
