.navContainer{
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 4px 13px rgba(220, 220, 220, 0.2);
  backdrop-filter: blur(64px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}

.navWrapper {
  max-width: 1200px;
  margin: auto;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navWrapper span img {
  cursor: pointer;
}

.navWrapper * {
  font-family: var(--codeville-font);
}

/* Nav link styles */
.mainWrapper {
  margin-top: 35px;
  position: fixed;
  top: 0;
  left: 0;
  padding: 20px 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  background-color: var(--codeville-color-white);
}

.mainWrapper.white {
  margin-top: 0px;
  background-color: var(--codeville-color-white);
}

.navBarBanner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 35px;
  background: #ffcb17;
}

.mainWrapper.mobile {
  background-color: #fff !important;
}

.caretDown {
  margin-left: 0px;
  cursor: pointer;
}

.caretDown.activeDropList path {
  fill: var(--codeville-link-active);
}

/* 
=========================
Navbar links
=========================
*/
.allLinksWrapper {
  display: flex;
  align-items: center;
}

.allLinksWrapper.mobile > svg {
  cursor: pointer;
}

.linksWrapper {
  display: flex;
  align-items: center;
}

.linksContainer {
  display: flex;
  list-style: none;
  margin: 0;
  position: relative;
  margin-right: 90px;
}

@media screen and (max-width: 1100px) {
  .linksContainer {
    margin-right: 20px;
  }
}

.linksContainer.mobile {
  position: absolute;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100vh;
  box-shadow: 0px 1px 5px rgba(225, 236, 255, 0.5);
  padding: 8px 50px;
  padding-bottom: 104px;
  padding-right: 0px;
}

.linkItem {
  padding: 8px;
  margin: 0px 16px;
  position: relative;
  color: var(--codeville-color-space-cadet);
}

.linkItem.disabled,
.linkItem .disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.linkItem:hover .disabled,
.linkItem.disabled {
  color: var(--codeville-color-space-cadet) !important;
}

.linkItem.mobile {
  margin-left: 0px;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.linkItem > div,
.linkItem.mobile > div {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.linkItem > div a,
.linkItem.mobile > div a,
.linkText {
  text-decoration: none;
  color: var(--codeville-color-space-cadet);
  width: 100%;
  text-align: left;
}

.linkItem > div a:hover,
.linkItem > div:hover,
.linkText:hover {
  color: var(--codeville-link-hover);
}

.linkItem > div:hover svg path {
  fill: var(--codeville-link-hover);
}

.isActiveLinkWithDropdown {
  color: var(--codeville-link-active);
}

.linkItem .active,
.linkItem.mobile .active {
  color: var(--codeville-link-active);
  font-weight: 700;
}

.mainWrapper.white .linkItem > div a,
.mainWrapper.white .linkItem.mobile > div a,
.mainWrapper.white .linkItem > div,
.mainWrapper.white .linkItem.mobile > div {
  color: var(--codeville-color-space-cadet);
}

.linkItem.mobile > div {
  padding-right: 42px;
}

@media screen and (max-width: 500px) {
  .linksContainer.mobile {
    padding: 16px;
    padding-right: 0px;
  }

  .linkItem.mobile > div {
    padding-right: 13px;
  }
}

.linkItem.mobile,
.linkItem.mobile > a {
  text-decoration: none;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.01em;
  color: var(--codeville-color-white);
}

.linkItem,
.linkItem > a,
.allLinksWrapper > a {
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  color: var(--codeville-color-space-cadet);
  cursor: pointer;
  display: flex;
  align-items: center;
}

.allLinksWrapper > a {
  margin-right: 25px;
}

.dropdownList {
  padding: 8px 0px;
  list-style-type: none;
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(193, 199, 208, 0.4);
  border-radius: 8px;
}

.dropdownList.mobile {
  width: 100%;
  transform: unset;
  position: unset;
  box-shadow: none;
}

.dropListItem {
  max-width: 300px;
  padding: 8px;
  display: flex;
}

.dropListItem.mobile {
  width: 100%;
  max-width: unset;
  padding: 8px;
  padding-left: 20px;
  text-align: left;
}

.dropListItem a {
  min-width: 250px;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: var(--codeville-color-space-cadet);
  text-decoration: none;
}

.dropListItem a:hover {
  text-decoration: underline;
}

@media screen and (min-width: 1440px) {
}

@media screen and (max-width: 1044px) {
}

@media screen and (max-width: 480px) {
  .linksContainer.mobile {
    background-color: #fff !important;
    height: 100vh;
  }
}
