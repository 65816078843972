.feedbackContainer {
  min-height: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 84px;
  background-image: url("data:image/svg+xml,%3Csvg width='316' height='316' viewBox='0 0 316 316' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='158' cy='158' r='158' fill='%2303A878'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: bottom 100px right 20px;
}

.feedbackContainer h6 {
  color: #56c2df;
  margin-bottom: 12px;
}

.feedbackContainer > h2 {
  font-weight: 600;
  font-size: 48px;
  line-height: 110%;
  color: var(--codeville-color-space-cadet);
  margin-bottom: 71px;
  text-align: center;
}
.feedbackCard {
  position: relative;
  background-color: transparent;
}

@media screen and (max-width: 1100px) {
  .feedbackContainer {
    background-position: bottom 100px right -10%;
  }
}

@media screen and (max-width: 900px) {
  .feedbackContainer {
    background-position: bottom 50px right -20%;
  }
}

@media screen and (max-width: 900px) {
  .feedbackContainer {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media screen and (max-width: 500px) {
  .feedbackContainer {
    background-position: bottom 20px right -45px;
    background-size: 158px 158px;
    min-height: unset;
    padding-bottom: 68px;
  }

  .feedbackContainer h2 {
    font-size: 24px;
    margin-bottom: 40px;
  }

  .feedbackContainer h6 {
    font-size: 13px;
  }
}
