.inputWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
}

.inputWrapper>div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.inputWrapper>div>p {
    color: #FF8170;
    font-size: 10px;
    margin-top: 5px;
}

.inputLabel {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #273043;
    margin-block-end: 12px;
}

.inputElement {
    font-family: 'Segoe UI';
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    outline: none;
    color: #8D96B4;
    box-sizing: border-box;
    padding: 13px 20px;
    border: 1px solid #DCDFE8;
    border-radius: 8px;
}

.inputElement::placeholder {
    color: #8D96B4;
    font-size: 16px;
}

.errorBorder {
    font-family: 'Segoe UI';
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    outline: none;
    color: #8D96B4;
    box-sizing: border-box;
    padding: 13px 20px;
    background: #FFFFFF;
    border: 1px solid #FF8170;
    border-radius: 8px;
}

.withIcon {
    position: relative;
    display: flex;
    align-items: center;
}

.iconElement {
    position: absolute;
    top: 12px;
    right: 20px;
}

.inputElement:disabled {
    cursor: not-allowed;
    background: #f2f2f2;
}