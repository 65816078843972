.container {
  position: relative;
  display: flex;
  justify-content: center;
  padding-bottom: 4rem;
  max-width: var(--learnable-max-width);
  margin: 0 auto;
}

.shapeOne,
.shapeTwo {
  display: none;
}

.content {
  position: relative;
  text-align: center;
  max-width: 48rem;
  background: var(--learnable-dark-purple);
  border-top: 2px solid var(--learnable-dark-purple);
  border-bottom-left-radius: 30rem;
  border-bottom-right-radius: 30rem;
  padding: 0 1.6rem;
  padding-bottom: 8rem;
}

.content:after {
  content: "";
  position: absolute;
  display: block;
  height: 100px;
  top: -100px;
  left: 0;
  right: 0;
  background: var(--learnable-dark-purple);
  z-index: -1;
}

.content h1 {
  font-size: 2.375rem;
  line-height: 3.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: var(--genesys-white);
}

.content p {
  color: var(--learnbale-border-color);
  line-height: 1.5rem;
}

.buttons {
  margin-top: 2rem;
}

.buttons button {
  width: 100%;
}

.buttons button:first-of-type {
  background: var(--learnable-dark-orange);
  border-color: var(--learnable-dark-orange);
  margin-bottom: 1.4rem;
  padding: 1rem 2rem;
}

.buttons button:disabled {
  background: var(--genesys-grey);
  border-color: var(--genesys-grey);
}

@media screen and (min-width: 420px) {
  .buttons {
    margin-top: 3rem;
  }

  .buttons button {
    width: auto;
  }

  .buttons button:first-of-type {
    margin-right: 1.2rem;
    margin-bottom: 0rem;
  }
}

@media screen and (min-width: 768px) {
  .content {
    border-bottom-left-radius: 40rem;
    border-bottom-right-radius: 40rem;
    padding-bottom: 6rem;
  }

  .content h1 {
    font-size: 2.8rem;
    font-weight: 700;
    line-height: 4rem;
  }

  .content p {
    font-size: 1.25rem;
    line-height: 1.875rem;
    padding: 0rem 2rem;
  }
}

@media screen and (min-width: 1200px) {
  .shapeOne,
  .shapeTwo {
    display: block;
    position: absolute;
  }

  .shapeOne {
    top: -170px;
    right: 0;
  }

  .shapeTwo {
    bottom: 160px;
    left: 120px;
  }

  .content {
    border-bottom-left-radius: 70rem;
    border-bottom-right-radius: 70rem;
  }

  .content h1 {
    font-size: 3.5rem;
    line-height: 5.25rem;
  }
}
