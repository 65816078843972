.funActivities {
  padding: 4rem 1.5rem;
  max-width: 1200px;
  margin: auto;
  position: relative;
  background-color: #ffffff;
  display: grid;
  gap: 20px;
  overflow: hidden;
}

.funActivities_header {
  display: grid;
  gap: 20px;
  text-align: center;
  max-width: 700px;
  margin: auto;
}

.funActivities_header span {
  background: linear-gradient(273.75deg, #e13f29 -1.55%, #fb7a68 -1.54%, #ec1840 104.27%);
  background-clip: text;
  -webkit-background-clip: text;
  color: rgb(0 0 0 / 20%);
  font-size: 16px;
  text-transform: uppercase;
}

.funActivities_header h1 {
  font-size: 48px;
  font-weight: 600;
  color: #17255a;
}

.funActivities_header p {
  font-size: 21px;
}

.funActivities_content {
  position: relative;
}

.funActivities_contentWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  align-items: stretch;
}

.funActivities_contentWrapper > * {
  min-height: 22rem;
  overflow: hidden;
}

.funActivities_imageContainer,
.funActivities_imageContainerLarge {
  position: relative;
  border: 1px solid #eeeeee;
  border-radius: 20px;
  overflow: hidden;
  display: grid;
  place-items: center;
}

.funActivities_imageContainer span, .funActivities_imageContainerLarge span{
  display: block;
  font-size: 16px;
  font-weight: 400;
  color: #CECDCD;
}

.funActivities_imageWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 1s ease-in-out;
  overflow: hidden;
}

.funActivities_imageWrapper img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.funActivities_imageContainerLarge {
  grid-column: span 2;
}

.funActivities_firstCard,
.funActivities_secondCard,
.funActivities_thirdCard {
  display: grid;
  /* place-items: center; */
  place-content: center;
  gap: 10px;
  padding: 1.5rem;
  border-radius: 20px;
}

.funActivities_firstCard p,
.funActivities_secondCard p,
.funActivities_thirdCard p {
  font-size: 21px;
  font-weight: 300;
  line-height: 1.5;
  color: #ffffff;
}

.funActivities_firstCard h2,
.funActivities_secondCard h2,
.funActivities_thirdCard h2 {
  font-size: 40px;
  font-weight: 600;
  color: #ffffff;
}

.funActivities_firstCard {
  background: #f5772f;
}

.funActivities_secondCard {
  background: #04a878;
}

.funActivities_thirdCard {
  background: #ff7da6;
}

/**************** Media Queries ***************/
/* @media screen and (max-width: 1024px) {
  .funActivities_contentWrapper{
    grid-template-columns: repeat(3, 1fr);
  }
} */
@media screen and (max-width: 960px) {
  .funActivities_contentWrapper {
    grid-template-columns: repeat(2, 1fr);
  }
  .funActivities_imageContainerLarge_2{
    order: 6;
    grid-column: span 2;
  }

  .funActivities_imageContainer,
  .funActivities_imageContainerLarge {
    object-position: top;
  }
}

@media screen and (max-width: 541px) {
  .funActivities_contentWrapper {
    grid-template-columns: auto;
  }
  .funActivities_imageContainerLarge {
    grid-column: auto;
    order: unset;
  }
}
