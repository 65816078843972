.container {
  position: relative;
  overflow: hidden;
  background: var(--genesys-light-grey);
  width: 100%;
  cursor: pointer;
}

.container:hover > .list-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(50px);
  background: linear-gradient(128.04deg, rgba(250, 92, 3, 0.08) -4.3%, rgba(250, 92, 3, 0.02) 103.42%),
    linear-gradient(128.26deg, rgba(255, 255, 255, 0.8) 21.81%, rgba(255, 255, 255, 0.2) 100%);
}

.text-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 14px;
  backdrop-filter: blur(50px);
  background: linear-gradient(128.04deg, rgba(250, 92, 3, 0.08) -4.3%, rgba(250, 92, 3, 0.02) 103.42%),
    linear-gradient(128.26deg, rgba(255, 255, 255, 0.8) 21.81%, rgba(255, 255, 255, 0.2) 100%);
}

.container h1 {
  font-size: 16px;
  font-family: var(--genesys-font-roboto);
  color: var(--genesys-purple);
  font-weight: 900;
}

.container h2 {
  font-size: 12px;
  font-family: var(--genesys-font-nunito);
  color: var(--genesys-purple);
  font-weight: 400;
}

.container ul {
  list-style: none;
}

.container li {
  margin-bottom: 25px;
}

.container a {
  text-transform: uppercase;
  text-decoration: none;
  color: var(--genesys-purple);
  font-family: var(--genesys-font-nunito);
  font-weight: 600;
}

.container a:hover {
  text-decoration: underline;
}

.list-container {
  position: absolute;
  display: none;
}

@media screen and (min-width: 768px) {
  .container h1 {
    font-size: 20px;
  }

  .container h2 {
    font-size: 13px;
  }
}
