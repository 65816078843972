.contactUsContainer {
  padding-top: 120px;
  display: flex;
  flex-direction: column;
}

.contactUsHeader {
  padding-left: 172px;
  background-image: url("../../assets/images/codeville/homeHeroDoodle.png");
  background-repeat: no-repeat;
  background-position: right 273px top 100px;
  margin-bottom: 31px;
}

.contactUsHeader h6 {
  background: -webkit-linear-gradient(273.75deg, #e13f29 -1.55%, #fb7a68 -1.54%, #ec1840 104.27%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 8px;
}

.contactUsHeader h2,
.waysToReachUs h2,
.waysToReachChannel h4,
.weAreGenContainer h2 {
  font-weight: 600;
  font-size: 60px;
  line-height: 120%;
  letter-spacing: -1px;
  color: var(--codeville-color-space-cadet);
  max-width: 700px;
}

.waysToReachUs h2,
.weAreGenContainer h2 {
  font-weight: 600;
  font-size: 48px;
  line-height: 110.19%;
  margin-bottom: 112px;
}

.waysToReachUs h2 {
  text-align: center;
  padding-left: 16px;
  padding-right: 16px;
}

.waysToReachChannel h4 {
  font-weight: 600;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: unset;
  margin-bottom: 23px;
}

.inputsContainer {
  display: flex;
  flex-direction: column;
  padding: 0px 170px;
  margin-bottom: 86px;
}

.inputsGroupWrapper {
  display: flex;
}

.inputsGroupWrapper .input {
  margin-right: 26px;
  margin-top: 17px;
}

.mapContainer {
  display: flex;
  padding-left: 64px;
  padding-right: 64px;
  width: 100%;
  margin-bottom: 120px;
}

.map {
  width: 100%;
  border-radius: 32px;
  border: none;
  min-height: 460px;
}

/* Ways to reach us */
.waysToReachUs {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.waysToReachChannels {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 90px;
}

.waysToReachChannel {
  display: flex;
  flex-direction: column;
  margin-left: 45px;
  margin-right: 45px;
  max-width: 272px;
  margin-bottom: 30px;
}

.waysToReachChannel h4 {
  display: flex;
  align-items: center;
}

.waysToReachChannel h4 .svg {
  margin-right: 16px;
  width: 32px;
  height: 32px;
}

.waysToReachChannel p,
.waysToReachChannel p a {
  font-weight: 300;
  font-size: 18px;
  line-height: 145%;
  margin-bottom: 8px;
  text-decoration: none;
  color: var(--codeville-color-space-cadet);
}

.waysToReachChannel:nth-of-type(2) p {
  max-width: 237px;
}

/* We Are Genesys */
.weAreGenContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 173px;
}

.weAreGenContainer h2 {
  margin-bottom: 48px;
  text-align: center;
}

.weAreGenContainer img {
  width: 100%;
  max-width: 906px;
}

@media screen and (max-width: 1000px) {
  .inputsContainer,
  .contactUsHeader {
    padding: 0px 60px;
  }

  .contactUsHeader {
    background-position: right 200px bottom -30px;
  }
}

@media screen and (max-width: 630px) {
  .contactUsHeader {
    background-position: right 105px top 58px;
  }

  .contactUsHeader h2 {
    font-size: 42px;
  }

  .waysToReachChannel h4 {
    justify-content: center;
  }

  .waysToReachChannel p {
    text-align: center;
  }
}

@media screen and (max-width: 560px) {
  .contactUsContainer {
    padding-top: 42px;
  }
  .contactUsHeader {
    background: none;
  }

  .inputsGroupWrapper {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .inputsContainer {
    align-items: center;
  }

  .inputsGroupWrapper .input {
    margin-right: 0px;
  }

  .mapContainer {
    padding: 0px;
  }
}

@media screen and (max-width: 450px) {
  .inputsContainer,
  .contactUsHeader {
    padding: 0px 16px;
  }

  .weAreGenContainer {
    margin-bottom: 60px;
  }

  .contactUsHeader h2,
  .waysToReachUs h2,
  .weAreGenContainer h2 {
    font-size: 34px;
  }

  .weAreGenContainer h2 {
    margin-bottom: 50px;
  }

  .waysToReachUs h2 {
    max-width: 304px;
    margin-bottom: 69px;
  }

  .contactUsHeader h6 {
    font-size: 13px;
    margin-bottom: 4px;
  }

  .waysToReachChannels {
    margin-bottom: 64px;
  }

  .waysToReachChannel {
    margin-left: 0px;
    margin-right: 0px;
  }

  .waysToReachChannel h4 {
    font-size: 24px;
  }

  .waysToReachChannel p {
    font-size: 16px;
  }
}
