.intentionMainContainer {
  display: flex;
  flex-direction: column;
}

/* Section styles*/
.intentionSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.intentionSection h6 {
  color: #56c2df;
}

.intentionSection h2 {
  font-weight: 600;
  font-size: 48px;
  line-height: 110.19%;
  color: var(--codeville-color-space-cadet);
  margin-bottom: 32px;
}

.intentionSection:nth-of-type(2) h6 {
  background: -webkit-linear-gradient(273.75deg, #e13f29 -1.55%, #fb7a68 -1.54%, #ec1840 104.27%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.intentionSection:nth-of-type(2) h2 {
  max-width: 528px;
}

.intentionSection:nth-of-type(2) p {
  max-width: 435px;
}

.intentionSection p {
  font-weight: 300;
  font-size: 20px;
  line-height: 145%;
}

.intentionSection p:first-of-type:not(:only-of-type) {
  margin-bottom: 32px;
}

.intentionSection:first-of-type {
  padding: 80px 80px;
  padding-left: 172px;
}

.intentionSection:first-of-type P {
  max-width: 600px;
}

.intentionSectionContent {
  margin-right: 20px;
}

.imageContainer {
  display: flex;
  align-items: center;
}

.intentionSection:nth-of-type(2) {
  justify-content: center;
  padding: 80px 180px;
  padding-right: 175px;
}

.intentionSection:nth-of-type(2) .imageContainer {
  flex-direction: column;
  margin-right: 102px;
  position: relative;
  height: 612px;
  width: 100%;
  max-width: 477px;
}

.intentionSection:nth-of-type(2) .imageContainer img {
  width: 327.11px;
  height: 299.59px;
  position: absolute;
}
.intentionSection:nth-of-type(2) .imageContainer img:nth-child(1) {
  right: 0px;
  top: 0px;
}
.intentionSection:nth-of-type(2) .imageContainer img:nth-child(2) {
  bottom: 45px;
  left: 0px;
}

@media screen and (max-width: 1300px) {
  .intentionSection:first-of-type,
  .intentionSection:last-of-type {
    padding: 80px 60px;
  }

  .intentionSection:first-of-type .imageContainer img {
    width: 100%;
  }
}

@media screen and (max-width: 850px) {
  .intentionSection:first-of-type {
    flex-direction: column-reverse;
    padding-bottom: 65px;
    padding-top: 64px;
  }

  .intentionSectionContent {
    margin-right: 0px;
    align-self: flex-start;
    width: 100%;
  }

  .intentionSection:nth-of-type(2) p {
    max-width: unset;
  }

  .intentionSection:last-of-type {
    flex-direction: column;
    padding-top: 0px;
    padding-bottom: 60px;
    margin-top: 32px;
  }

  .intentionSection:nth-of-type(2) .imageContainer {
    margin-right: 0px;
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .intentionSection:first-of-type,
  .intentionSection:last-of-type {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 68px;
    padding-top: 32px;
  }

  .intentionSection:first-of-type {
    padding-top: 64px;
  }

  .intentionSection h2 {
    font-size: 24px;
    line-height: 120%;
  }

  .intentionSection h6 {
    font-size: 13px;
    line-height: 120%;
    margin-bottom: 11px;
  }

  .intentionSection p {
    font-size: 16px;
    line-height: 145%;
  }

  .intentionSection:nth-of-type(2) .imageContainer {
    height: 441.44px;
  }

  .intentionSection:nth-of-type(2) .imageContainer img {
    max-width: 250px;
    max-height: 215.1px;
  }
}
