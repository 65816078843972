.container {
  padding-bottom: 3rem;
}

.grid {
  display: none;
}

.glider {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  gap: 2rem;
  margin-top: 2rem;
}

.glider-buttons {
  position: absolute;
  bottom: 20px;
  right: 50%;
  transform: translateX(50%);
  z-index: 10;
}

.glider-buttons button {
  background: none;
  border: 1px solid var(--learnable-dark-orange);
  border-radius: 50%;
  padding: 0.55rem 0.7rem;
  cursor: pointer;
  vertical-align: middle;
  transition: 0.7s;
}

.glider-buttons button:hover {
  transform: scale(0.9);
}

.glider-buttons button:first-of-type {
  margin-right: 1rem;
}

.name {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.3rem;
}

.company {
  font-size: 0.85rem;
  color: var(--learnable-text-color);
}

.comment {
  margin-top: 1rem;
  font-size: 0.85rem;
  color: var(--learnable-text-color);
}

.read-more {
  z-index: 20;
  color: var(--learnable-dark-orange);
  cursor: pointer;
}

.image-container {
  background: var(--learnable-orange);
  width: 100%;
  height: 290px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
}

.image-content {
  display: none;
  width: 100%;
  height: 100%;
  animation: fadeIn ease 2s;
  border-radius: 50%;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.text-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: var(--learnbale-border-color);
  border-top-left-radius: 10rem;
  border-top-right-radius: 10rem;
  padding: 1rem;
  height: 365px;
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.04);
  text-align: center;
  overflow: hidden;
}

.text-content {
  display: none;
  animation: slide-in 1s forwards;
}

.active {
  display: block;
}

@media screen and (min-width: 320px) {
  .image-container {
    width: 320px;
    height: 320px;
  }

  .text-container {
    width: 90%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 768px) {
  .container {
    padding-bottom: 5rem;
  }

  .glider {
    margin-top: 4rem;
  }

  .glider-buttons {
    top: 70px;
    right: 80px;
    bottom: 250px;
  }

  .image-container {
    width: 428px;
    height: 428px;
  }

  .text-container {
    position: relative;
    height: 380px;
    width: 100%;
    justify-content: flex-start;
    background: var(--learnbale-border-color);
    border-top-left-radius: 10rem;
    border-top-right-radius: 0rem;
    border-bottom-left-radius: 10rem;
    padding: 4rem;
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.04);
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.04);
    text-align: left;
  }

  .name {
    font-size: 1.5rem;
  }

  .company {
    font-size: 1.25rem;
  }

  .comment {
    font-size: 1.25rem;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    padding-bottom: 7rem;
  }

  .grid {
    display: grid;
    grid-template-columns: 0.5fr 2fr 3fr 2fr 0.5fr;
    gap: 1rem;
    margin-top: 7rem;
    margin-bottom: 8rem;
  }

  .grid > div:first-of-type,
  .grid > div:nth-of-type(2),
  .grid > div:nth-of-type(4) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .grid > div:first-of-type::before {
    display: block;
    content: "";
    width: 100%;
    height: 40%;
    background: var(--learnable-orange);
    border-radius: 6rem;
  }

  .grid > div:first-of-type::after {
    display: block;
    content: "";
    width: 100%;
    height: 55%;
    background: var(--learnable-yellow);
    border-radius: 6rem;
  }

  .grid > div:nth-of-type(2)::after,
  .grid > div:nth-of-type(4)::before {
    content: "";
    display: block;
    width: 100%;
    height: 40%;
    background: var(--learnable-dark-purple);
    border-radius: 10rem;
  }

  .grid-2-image-container,
  .grid-4-image-container {
    position: relative;
    width: 310px;
    height: 310px;
  }

  .grid-2-image-container img,
  .grid-3-image-container img,
  .grid-4-image-container img {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .grid > div:nth-of-type(3) {
    display: flex;
    justify-content: center;
  }

  .grid-3-image-container {
    position: relative;
    width: 422px;
    height: 560px;
  }

  .grid > div:last-of-type::after {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background: var(--learnable-green);
    border-radius: 6rem;
  }

  .glider {
    grid-template-columns: 1fr 2fr;
    align-items: center;
    gap: 2rem;
    height: 428px;
  }

  .text-container {
    overflow: visible;
  }

  .text-container::before {
    content: "";
    position: absolute;
    top: 0;
    right: -115px;
    width: 20%;
    height: 100%;
    background: var(--learnbale-border-color);
  }
}

@keyframes slide-in {
  0% {
    transform: translateX(5%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
