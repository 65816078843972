.navigate {
  margin-bottom: 60px;
  text-transform: uppercase;
  color: var(--genesys-red);
  font-family: var(--genesys-font-nunito);
  transition: 0.3s;
}

.navigate span {
  cursor: pointer;
}

.customLink {
  text-decoration: none; 
}

.navigate:hover {
  transform: scaleX(1.02);
  text-decoration: underline;
}

.container section {
  margin-bottom: 55px;
}

.container section:nth-of-type(1),
.container section:nth-of-type(4) {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.container section:nth-of-type(1) img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container section:nth-of-type(2) {
  margin: 70px 0px;
}

.container section:nth-of-type(2),
.partners div {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.partners > p {
  margin-bottom: 35px;
  font-family: var(--genesys-font-roboto);
  font-weight: 400;
  font-size: 20px;
  color: var(--genesys-grey);
}

.partners div img {
  width: 100px;
  object-fit: contain;
}

.partners div img:first-of-type {
  margin-right: 20px;
}

.container section:nth-of-type(1) button {
  display: block;
  margin: 0 auto;
}

.container section:nth-of-type(1) > div:last-of-type,
.container section:nth-of-type(4) > div:last-of-type {
  margin-top: 50px;
  margin-left: auto;
}

.container section:nth-of-type(1) > div p,
.container section:nth-of-type(3) > div p,
.container section:nth-of-type(4) > div p {
  font-family: var(--genesys-font-nunito);
  font-weight: 400;
  font-size: 16px;
  line-height: 27.51px;
  color: var(--genesys-grey);
  margin-bottom: 20px;
}

.start-up-boost-grid {
  display: grid;
  row-gap: 30px;
  margin-top: 60px;
  margin-bottom: 90px;
}

.container section:nth-of-type(4) {
  position: relative;
  background: var(--genesys-bg-light-grey);
  padding-bottom: 60px;
}

.container section:nth-of-type(4)::before {
  content: "";
  position: absolute;
  left: -16px;
  height: 100%;
  width: 90vw;
  background: var(--genesys-bg-light-grey);
}

.container section:nth-of-type(4)::after {
  content: "";
  position: absolute;
  top: 0;
  right: -16px;
  height: 100%;
  width: 90vw;
  background: var(--genesys-bg-light-grey);
}

.container section:nth-of-type(4) > div {
  position: relative;
  z-index: 4;
  padding: 80px 0px;
}

.container section:nth-of-type(4) > div:last-of-type {
  padding: 0px 0px;
  margin-top: -40px;
}

@media screen and (min-width: 768px) {
  .navigate {
    margin-bottom: 60px;
  }

  .container section:nth-of-type(1) button {
    display: inline;
  }

  .container section:nth-of-type(1) > div {
    width: 65%;
  }

  .container section:nth-of-type(2) {
    margin: 90px 0px;
  }

  .partners > p {
    margin-bottom: 55px;
  }

  .partners div img {
    width: 160px;
  }

  .partners div img:first-of-type {
    margin-right: 40px;
  }

  .start-up-boost-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 40px;
    margin-bottom: 150px;
  }

  .container section:nth-of-type(4) > div:first-of-type {
    width: 60%;
  }

  .container section:nth-of-type(4) > div:last-of-type {
    width: 100%;
  }

  .container section:nth-of-type(3) > div > p {
    width: 58%;
  }

  .container section:nth-of-type(4)::before {
    width: 100vw;
  }

  .container section:nth-of-type(4)::after {
    width: 100vw;
  }
}

@media screen and (min-width: 1200px) {
  .navigate {
    margin-bottom: 120px;
  }

  .container section:nth-of-type(1) {
    margin-bottom: 120px;
  }

  .container section:nth-of-type(1) > div:first-of-type {
    width: 35%;
  }

  .container section:nth-of-type(1) > div:last-of-type {
    width: 50%;
  }

  .container section:nth-of-type(2) {
    margin: 120px 0px;
  }

  .partners div img:first-of-type {
    margin-right: 50px;
  }

  .container section:nth-of-type(4) > div:first-of-type {
    width: 30%;
  }

  .container section:nth-of-type(4) > div:last-of-type {
    width: 45%;
    padding: 80px 0px;
    margin-top: 0px;
  }

  .container section:nth-of-type(1) > div:last-of-type,
  .container section:nth-of-type(4) > div:last-of-type {
    margin-top: 0px;
  }

  .start-up-boost-grid {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  .start-up-boost-grid > div:nth-of-type(2),
  .start-up-boost-grid > div:nth-of-type(4) {
    grid-row: 1 / span 2;
    align-self: center;
  }

  .start-up-boost-grid > div:nth-of-type(2) {
    grid-column: 2;
  }
  .start-up-boost-grid > div:nth-of-type(4) {
    grid-column: 4;
  }

  .container section:nth-of-type(4)::before {
    content: "";
    position: absolute;
    left: -300px;
    height: 100%;
    width: 100vw;
    background: var(--genesys-bg-light-grey);
  }

  .container section:nth-of-type(4)::after {
    content: "";
    position: absolute;
    top: 0;
    right: -300px;
    height: 100%;
    width: 100vw;
    background: var(--genesys-bg-light-grey);
  }

  .faq-container > div:last-of-type {
    margin-left: -40px;
  }
}
