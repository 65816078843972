.wrap {
    width: 100%;
}

.mainWrap {
    width: 100%;
    padding: 119px 300px 50px;
}

.inputWrap1 {
    display: flex;
    justify-content: space-between;
    width: 98%;
}

.inputFile {
    display: none;
}

.inputFileWrap {
    border: 2px dashed #DFE1E6;
    border-radius: 4px;
    width: 98%;
    height: 156px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-block-start: 64px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-block-start: 12px;
}

.inputFileWrap label {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #7A869A;

}

.resumePlaced {
    font-size: 12px;
    padding: 5px;
}

.inputFileWrap span {
    color: #E13F29;
}

.resumeError {
    text-align: center;
    color: #FF8170;
    font-size: 12px;
    margin-top: 18px;
}

.inputWrap {
    display: flex;
    justify-content: space-between;
    width: 98%;
    margin: 40px 0;
}



.inputWrap1>div:nth-child(1),
.inputWrap>div:nth-child(1) {
    margin-right: 24px;
}

.buttonWrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: #FFF;
    box-shadow: 0px 2px 30px rgba(6, 18, 60, 0.04);
    width: 30%;
    margin-top: 32px;
}

.submitButton {
    padding: 19.5px 32px;
    cursor: pointer;
}


.resumeLabel {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #273043;
    margin-block-start: 32px;
}

@media screen and (max-width: 202px) {
    .mainWrap {
        width: 90%;
        padding: 0 5px;
        margin: auto;
    }

}

@media screen and (max-width: 576px) {
    .mainWrap {
        width: 100%;
        padding: 0 20px !important;
    }

    .mainWrap>div:nth-child(5)>div {
        width: 100%;
    }

    .inputWrap,
    .inputWrap1 {
        flex-wrap: wrap;
        margin: 0;
    }

    .inputWrap>div:nth-child(1),
    .inputWrap1>div:nth-child(1) {
        margin: 15px 0;
    }

    .mainWrap>div:last-child {
        width: 100%;
        margin: 15px auto;
    }

    .buttonWrap {
        margin-top: 92px;
    }

    .uploadWrap {
        margin-block-start: 24px;
    }

}

@media screen and (max-width: 768px) {
    .mainWrap {
        width: 100%;
        padding: 50px;
    }
}

@media screen and (max-width: 1024px) {
    .mainWrap {
        width: 100%;
        padding: 60px 100px 304px 100px;
    }

}