.mainContainer {
  background-color: #fafafa;
}

.teachSection {
  min-height: 900px;
  padding: 88px 171px 69px 176px;
}

.teachCardContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.teachSectionContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 65px;
}

.teachSectionContent p {
  max-width: 490px;
}

.teachSectionContentHeader {
  margin-right: 20px;
}

.teachSectionContentHeader h6,
.funActivitiesSectionHeader h6 {
  background: -webkit-linear-gradient(273.75deg, #e13f29 -1.55%, #fb7a68 -1.54%, #ec1840 104.27%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.teachSectionContentHeader h2,
.experienceSectionHeader h2,
.funActivitiesSectionHeader h2 {
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 110.19%;
  color: var(--codeville-color-space-cadet);
  text-align: center;
}

.teachSectionContentHeader h2 {
  text-align: left;
}

.funActivitiesSectionHeader h2 {
  text-align: left;
  margin-bottom: 24px;
}

.experienceSectionHeader h6 {
  color: #56c2df;
}

.teachCardItem {
  margin-right: 24px;
  margin-bottom: 24px;
  max-width: 340px;
}

@media screen and (max-width: 1440px) {
  .teachCardItem {
    max-width: unset;
    width: calc(50% - 24px);
    min-width: 346px;
  }
}
@media screen and (max-width: 1285px) {
  .teachSectionContentHeader {
    margin-bottom: 16px;
  }
}
@media screen and (max-width: 1080px) {
  .teachCardContainer {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
  }

  .teachCardItem {
    min-width: unset;
    width: 100%;
  }

  .teachCardItem p {
    width: unset;
  }
}

@media screen and (max-width: 850px) {
  .teachSection {
    padding: 60px;
  }
}

@media screen and (max-width: 450px) {
  .teachSection {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 32px;
    padding-bottom: 50px;
  }

  .teachSectionContentHeader h2 {
    font-size: 24px;
    line-height: 120%;
  }

  .teachSectionContentHeader h6 {
    font-size: 13px;
    line-height: 120%;
    margin-bottom: 11px;
  }

  .teachSection p {
    font-size: 16px;
    line-height: 145%;
  }
}
/* Experoence Section */
.experienceSection {
  padding: 88px 64px;
  padding-bottom: 50px;
  min-height: 926px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.experienceSectionHeader {
  text-align: center;
  margin-bottom: 64px;
}

.experienceProgramCardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.experienceProgramCard {
  margin-left: 12px;
  margin-right: 12px;
  min-height: 566px;
  margin-bottom: 24px;
}

@media screen and (max-width: 500px) {
  .experienceSection {
    padding: 88px 30px;
  }
  .experienceProgramCard {
    min-width: 100%;
  }

  .teachCardContainer {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 450px) {
  .experienceSection {
    padding: 88px 16px;
  }

  .experienceProgramCard h1 {
    font-size: 20px;
    line-height: 135%;
  }
}

/* Fun Activities Section */
.funActivitiesSection {
  display: flex;
  align-items: center;
  padding: 117px 175px;
}

.funActivitiesSectionHeader {
  margin-right: 80px;
  min-width: 476px;
}

.funActivitiesSectionHeader p {
  max-width: 476px;
  margin-bottom: 48px;
}

.funActivitiesSectionHeader ul {
  list-style-type: none;
}

.funActivitiesSectionHeader ul li {
  display: flex;
  align-items: center;
  background-color: var(--codeville-color-white);
  font-weight: 300;
  font-size: 30px;
  line-height: 145%;
  color: var(--codeville-text-color);
  min-height: 76px;
  margin-bottom: 24px;
  padding: 16px;
  border-radius: 16px;
}

.funActivitiesSectionHeader ul li svg {
  margin-right: 8px;
}

.funActivitiesSectionImage,
.funActivitiesSectionImage img {
  width: 100%;
}

@media screen and (max-width: 1280px) {
  .funActivitiesSection {
    padding: 117px 60px;
  }
}
@media screen and (max-width: 970px) {
  .funActivitiesSection {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .funActivitiesSectionImage {
    margin-bottom: 48px;
  }

  .funActivitiesSectionHeader {
    margin-right: 0px;
    min-width: unset;
  }
}

@media screen and (max-width: 600px) {
  .funActivitiesSection {
    padding: 62px 30px;
  }
}
@media screen and (max-width: 500px) {
  .funActivitiesSection {
    padding: 62px 16px;
  }

  .funActivitiesSectionHeader h2 {
    font-size: 24px;
    margin-bottom: 24px;
  }

  .funActivitiesSectionHeader p {
    font-size: 14px;
  }
  .funActivitiesSectionHeader ul li {
    font-size: 18px;
  }
  .funActivitiesSectionHeader ul li svg {
    width: 24px;
    height: 24px;
  }
}
@media screen and (min-width: 1280px) {
  .experienceProgramCardContainer {
    flex-wrap: nowrap;
  }
}
