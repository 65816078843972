.container section {
  margin-bottom: 55px;
}

.container section:nth-of-type(2) {
  width: 100%;
  height: 200px;
  background: url("../../assets/images/businessIncubationImg1.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.section-3-flex {
  display: flex;
  flex-direction: column;
}

.section-3-flex p {
  font-family: var(--genesys-font-nunito);
  font-weight: 400;
  font-size: 16px;
  line-height: 27.51px;
  color: var(--genesys-grey);
  margin-bottom: 20px;
}

.section-3-flex div:nth-of-type(2) {
  margin-top: 50px;
}

.section-3-flex div:nth-of-type(2) img {
  width: 100%;
}

.container section:nth-of-type(4) {
  position: relative;
  background: var(--genesys-bg-light-grey);
  padding-bottom: 60px;
}

.container section:nth-of-type(4)::before {
  content: "";
  position: absolute;
  left: -16px;
  height: 100%;
  width: 90vw;
  background: var(--genesys-bg-light-grey);
}

.container section:nth-of-type(4)::after {
  content: "";
  position: absolute;
  top: 0;
  right: -16px;
  height: 100%;
  width: 90vw;
  background: var(--genesys-bg-light-grey);
}

.container section:nth-of-type(4) > div {
  position: relative;
  z-index: 4;
  padding: 80px 0px;
}

.incubation-cards-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -120px;
}

.incubation-cards-container > div:last-of-type {
  margin-top: 50px;
}

@media screen and (min-width: 768px) {
  .container section {
    margin-bottom: 70px;
  }

  .container section:nth-of-type(2) {
    height: 400px;
  }

  .incubation-cards-container > div:last-of-type {
    margin-top: 80px;
  }
}

@media screen and (min-width: 992px) {
  .section-3-flex {
    flex-direction: row;
  }

  .section-3-flex div:nth-of-type(2) img {
    width: 457px;
  }

  .section-3-flex div:nth-of-type(2) {
    margin-top: 80px;
  }

  .container section:nth-of-type(4)::before {
    width: 100vw;
  }

  .container section:nth-of-type(4)::after {
    width: 100vw;
  }

  .incubation-cards-container {
    margin-top: -220px;
    flex-direction: row;
    justify-content: space-around;
  }

  .incubation-cards-container > div:last-of-type {
    margin-top: 220px;
  }
}

@media screen and (min-width: 1200px) {
  .container section {
    margin-bottom: 90px;
  }

  .container section:nth-of-type(2) {
    height: 480px;
  }

  .section-3-flex div:nth-of-type(2) img {
    width: 557px;
  }

  .container section:nth-of-type(4)::before {
    content: "";
    position: absolute;
    left: -300px;
    height: 100%;
    width: 100vw;
    background: var(--genesys-bg-light-grey);
  }

  .container section:nth-of-type(4)::after {
    content: "";
    position: absolute;
    top: 0;
    right: -300px;
    height: 100%;
    width: 100vw;
    background: var(--genesys-bg-light-grey);
  }
}
