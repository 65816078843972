.mainContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 92px;
  padding-bottom: 196px;
  padding-left: 146px;
  padding-right: 124px;
}

.textContent {
  margin-right: 41px;
  min-width: 492px;
}

.textContent h1 {
  font-weight: 600;
  font-size: 60px;
  line-height: 120%;
  letter-spacing: -1px;
  max-width: 492px;
  margin-bottom: 64px;
}

.imageContainer,
.imageContainer img {
  width: 100%;
}

@media screen and (max-width: 1150px) {
  .textContent {
    min-width: unset;
  }
}
@media screen and (max-width: 950px) {
  .mainContainer {
    flex-direction: column-reverse;
    justify-content: flex-end;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 68px;
    padding-bottom: 74px;
    min-height: 100vh;
  }

  .textContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 0px;
  }
  .textContent h1 {
    text-align: center;
  }
}

@media screen and (max-width: 500px) {
  .mainContainer {
    padding-left: 20px;
    padding-right: 20px;
  }
  .textContent h1 {
    font-size: 34px;
    line-height: 110.19%;
    letter-spacing: 0.25px;
    max-width: 338px;
    margin-bottom: 32px;
  }
}
