.vanguard {
  padding: 4rem 1.5rem;
  max-width: 1200px;
  margin: auto;
  display: grid;
  gap: 4rem;
}

.vanguard_header {
  display: grid;
  gap: 2rem;
  text-align: center;
  max-width: 500px;
  margin: auto;
}

.vanguard_header h1 {
  font-size: 42px;
}

.vanguard_header p {
  font-size: 16px;
}

.vanguard_content {
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.vanguard_contentWrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  place-items: stretch;
  gap: 15px;
  width: 100%;
}

.vanguard_contentWrapper_firstCard {
  grid-column: span 2 / span 2;
  display: flex;
  width: fit-content;
  margin: auto;
  padding: 20px 0;
  padding-left: 20px;
  border-radius: 20px;
  background: linear-gradient(273.75deg, #e13f29 -1.55%, #fb7a68 -1.54%, #ec1840 104.27%);
}

.vanguard_contentWrapper_firstCard > div {
  margin-right: -15px;
}

.vanguard_contentWrapper_firstCard img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.vanguard_contentWrapper_firstCard img:nth-child(2) {
  z-index: 10;
}

.vanguard_contentWrapper_secondCard {
  grid-column: span 3 / span 3;
  display: flex;
  background: linear-gradient(180deg, #71d0bf 0%, #04c28c 100%);
  border-radius: 20px;
  overflow: hidden;
}

.vanguard_contentWrapper_secondCard_content {
  display: grid;
  flex: 2 1 calc(60%);
  gap: 10px;
  align-items: center;
  padding: 30px;
}

.vanguard_contentWrapper_secondCard_content span {
  background: linear-gradient(273.75deg, #e13f29 -1.55%, #fb7a68 -1.54%, #ec1840 104.27%);
  background-clip: text;
  -webkit-background-clip: text;
  color: rgb(0 0 0 / 20%);
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}

.vanguard_contentWrapper_secondCard_content p {
  font-size: 24px;
  font-weight: 600;
  color: #17255a;
}

.vanguard_contentWrapper_secondCard_imageContainer {
  flex: 1 1 calc(40%);
}

.vanguard_contentWrapper_secondCard img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/**************** Media Queries ***************/
@media screen and (max-width: 960px) {
  .vanguard_contentWrapper {
    display: grid;
    grid-template-columns: auto;
    place-content: center;
  }
  .vanguard_contentWrapper_firstCard,
  .vanguard_contentWrapper_secondCard {
    grid-column: auto;
  }
}

@media screen and (max-width: 481px) {
  .vanguard_contentWrapper_secondCard {
    display: grid;
  }
}
