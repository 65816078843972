.faqTabContainer {
  width: 100%;
  max-width: 685px;
  width: fit-content;
  background: #eeeeee;
  border-radius: 50px;
  list-style-type: none;
  display: flex;
  margin-bottom: 60px;
  left: 0px;
  top: 150px;
  z-index: 10;
}

.faqTabItem {
  border-radius: 32px;
  padding: 17px 45px;
  cursor: pointer;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #596275;
}

.faqTabItem.activeFAQ {
  background: linear-gradient(273.75deg, #e13f29 -1.55%, #fb7a68 -1.54%, #ec1840 104.27%);
  color: var(--codeville-color-white);
}

@media screen and (max-width: 500px) {
  .faqTabItem {
    padding: 17px 30px;
  }
}
@media screen and (max-width: 450px) {
  .faqTabItem {
    width: 108px;
    padding-left: 0px;
    padding-right: 0px;
    justify-content: center;
  }
}
