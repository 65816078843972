.container {
  position: relative;
  max-width: var(--learnable-max-width);
  margin: 0 auto;
  padding-top: 4rem;
}

.faq-shape {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  margin-top: 3rem;
}

.card {
  display: grid;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.logo-container {
  display: inline-block;
  margin: 0 auto;
  background: var(--learnable-dark-orange);
  border: 8px solid var(--learnable-light-orange);
  border-radius: 50%;
  padding: 0.5rem;
  margin-bottom: 1rem;
}

.logo-container svg {
  vertical-align: middle;
  transition: 0.3s;
  animation: glow 1.5s ease-in-out infinite;
}

.card-header {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.4rem;
  color: var(--learnable-dark-blue);
}

.card-paragraph {
  color: var(--learnable-text-color);
  font-weight: 300;
  line-height: 1.5rem;
}

@media screen and (min-width: 768px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-start;
    gap: 2.5rem;
    margin-top: 5rem;
  }

  .card-header {
    font-size: 1.25rem;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    padding-top: 3rem;
  }

  .faq-shape {
    display: block;
  }

  .grid {
    grid-template-columns: repeat(3, 1fr);
    margin-top: 6rem;
    padding: 0rem 4rem;
  }
}

@keyframes glow {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
