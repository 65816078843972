.inputContainer {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: var(--codeville-color-white);
  padding: 17px 21px;
  outline: none;
  width: 100%;
  height: 54px;
  font-size: 16px;
  line-height: 24px;
}

.inputContainer::placeholder {
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.6);
}

.inputContainer.textareaInput {
  min-height: 139px;
}
