.investmentCardWrapper {
  font-family: var(--genesys-font-roboto);
  box-shadow: 0px 0px 8px 0px rgba(229, 229, 229, 0.5);
  height: 339px;
  padding: 30px;
  width: calc(calc(100% - 60px) / 3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 25px 10px;
}

.investmentCardWrapper p {
  color: var(--genesys-grey);
  font-size: 1rem;
  line-height: 25.89px;
  white-space: normal;
  margin: 30px 0px 50px;
}

.investmentCardWrapper a {
  display: flex;
  color: var(--genesys-red);
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  font-family: var(--genesys-font-nunito);
  text-transform: uppercase;
  text-decoration: underline;
}

.investmentCardWrapper a span {
  margin-left: 10px;
}

.link {
  width: 70%;
}

@media screen and (max-width: 900px) {
  .investmentCardWrapper {
    width: calc(calc(100% - 40px) / 2);
    height: 380px;
    padding: 25px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .investmentCardWrapper p {
    font-size: 0.875rem;
    line-height: 21.09px;
  }
  .investmentCardWrapper a span {
    font-size: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: wrap;
  }
  .invCardIconWrapper {
    width: 100%;
    height: 49.1px;
    object-fit: contain;
  }
  .invCardIconWrapper > img {
    object-fit: contain;
  }
}

@media screen and (max-width: 550px) {
  .investmentCardWrapper {
    width: 100%;
    height: 250px;
    padding: 25px;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 10px 0px;
  }

  .investmentCardWrapper img {
    font-size: 28px;
    line-height: 30.85px;
    margin: 10px 0px;
    height: 40px;
  }
  .investmentCardWrapper p {
    font-size: 0.875rem;
    line-height: 21.09px;
  }
  .investmentCardWrapper a {
    font-size: 12.22px;
  }
 
}
