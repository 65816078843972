.codeVilleMainContainer {
  background-color: var(--codeville-color-white);
}

.codeVillePageWrapper * {
  font-family: var(--codeville-font);
}

.codeVillePageWrapper {
  padding-top: 124px;
  margin: 0 auto;
  scroll-behavior: smooth;
}

.codeVillePageWrapper h1 {
  color: var(--codeville-color-space-cadet);
}

.codeVillePageWrapper p {
  font-weight: 300;
  line-height: 145%;
  color: var(--codeville-text-color);
}

.codeVillePageWrapper h6 {
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 16px;
  color: #c4c4c4;
}

.codeVilleVirtualModal {
  padding: 50px 25px;
  text-align: center;
}

.codeVilleVirtualModal h2  {
  color: var(--codeville-color-space-cadet);
  margin-bottom: 12px;
  font-size: 1.5rem;
}


.codeVilleVirtualModal p {
  font-size: 1rem;
  font-weight: 300;
  color: rgba(89, 98, 117, 1);
  margin-bottom: 15px;
  line-height: 23px;
}

.codeVilleVirtualModal button {
  margin: 0 auto;
}

.inputGroup{
  display: block;
  padding: .5rem 1rem;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  margin: 30px auto;
}

.inputGroup input{
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
}

.inputGroup:focus-within{
  border-color: #ee2646;
}

@media screen and (min-width: 768px) {
  .codeVilleVirtualModal {
    padding: 80px 180px;
  }

  .codeVilleVirtualModal h2 {
    margin-bottom: 20px;
    font-size: 2.125rem;
  }

  .codeVilleVirtualModal p {
    font-size: 1.435rem;
    margin-bottom: 50px;
  }
}
