.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  padding: 2.3125rem 2rem;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 4px 13px rgba(220, 220, 220, 0.2);
  backdrop-filter: blur(64px);
}

.big-header {
  display: none;
}

.small-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hamburger {
  width: 30px;
  height: 30px;
  margin-top: -7px;
}

.small-header img {
  width: 130px;
  height: 30px;
}

.small-header nav {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 100;
}

.small-header ul {
  padding-top: 4rem;
  padding-left: 2rem;
  height: 100vh;
  background: #fff;
}

.small-header li {
  margin-bottom: 2.5rem;
}

.cancel {
  position: absolute;
  right: 30px;
  bottom: 40px;
  width: 30px;
  height: 30px;
}

.container ul {
  list-style: none;
}

.container li {
  font-family: var(--genesys-font-nunito);
  font-weight: 600;
}

.container li a {
  text-decoration: none;
  color: var(--genesys-black);
}

.container li a:hover {
  color: var(--genesys-red);
  text-decoration: underline;
}

.container li:nth-of-type(2) a {
  display: inline-flex;
  align-items: flex-start;
}

li a.active {
  color: var(--genesys-red);
  text-decoration: underline;
}

@media screen and (min-width: 768px) {
  .container {
    margin-bottom: 4.5rem;
  }

  .small-header {
    display: none;
  }

  .big-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .container li {
    display: inline-block;
  }

  .container li:not(:last-of-type) {
    margin-right: 1.25rem;
  }
}

@media screen and (min-width: 1200px) {
  .container li:not(:last-of-type) {
    margin-right: 35px;
  }
}
