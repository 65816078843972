.heroImage {
    width: 100%;
    height: 261px;
    background-image: url("../../assets/images/heroImage.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.heroImage h4 {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #FEFEFE;
    margin-block-end: 21px;
}

.heroImage h1 {
    width: 40%;
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 110.19%;
    text-align: center;
    letter-spacing: -0.5px;
    color: #FEFEFE;
}


@media screen and (max-width: 568px) {
    .heroImage h1 {
        width: 80%;
        font-size: 32px;
    }

    .heroImage h4 {
        font-size: 14px;
    }
}