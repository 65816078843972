/* USED IN INVESTMENT AND ABOUT US PAGE */

.containerFluid {
    max-width: 100%;
    font-style: normal;
    padding: 100px 0;
}
.col {
    margin: auto;
    width: 52.45901630%; /* 640/1220  1220 From innitial page width */
}
.fullGridCardParagraph1{
    margin-top: 36px;
    font-family: Nunito;
    font-size: 1.0625rem; /* from 17px  design */
    line-height: 28px;
    letter-spacing: 0.5px;
    color: #7D7D7D;
    font-weight: 400;
}.fullGridCardParagraph2 {
    font-family: Nunito;
    font-size: 1.0625rem; /* 17px */
    line-height: 28px;
    letter-spacing: 0.5px;
    color: #7D7D7D;
    margin-top: 24px;
    font-weight: 400;
}
.headingWrapper > h6 {
    font-family: Nunito;
    font-weight: 600;
    font-size: 0.9375rem; /*15px from design */
    line-height: 110.19%;
    letter-spacing: 1.25px;
    color: #C4C4C4;
    margin-bottom: 16px;
}
.headingWrapper > h4 {
    font-family: Roboto;
    font-weight: 900;
    font-size: 3.75rem; /* 60px */
    line-height: 110.19%;
    letter-spacing: -0.5px;
    color: #17255A;
}

@media screen and (max-width: 769px) {
    .col {
        width: 100%;
    }
    .containerFluid {
        padding: 0px;
    }
    .headingWrapper { 
        margin-top: 50px;
    }
    .paragraphWrapper { 
        margin-top: 50px 0px;
    }
    .fullGridCardParagraph1  {
        width: 90%;
    }
    
    .fullGridCardParagraph2 {
        width: 90%;
        margin-bottom: 40px;
    }
    .fullGridCardParagraph1  {
        margin: 28px 0px;
    }
    .headingWrapper > h6 {
        font-family: Nunito;
        font-size: 13px;
        line-height: 21px;
        color: #C4C4C4;
        margin-bottom: 10px;
    }
    .headingWrapper > h4 {
        font-family: Roboto;
        font-weight: 900;
        font-size: 34px;
        line-height: 110.19%;
        letter-spacing: 0.25px;
        color: #17255A;
    }
}