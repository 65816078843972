.container section {
  margin-bottom: 50px;
}

.section-2-images {
  display: flex;
  align-items: center;
  overflow-x: scroll;
}

.section-2-images div {
  background: var(--genesys-grey);
}

.section-2-images div:not(:last-of-type) {
  margin-right: 20px;
}

.section-2-images div:nth-of-type(1) {
  min-width: 147.61px;
  height: 169.52px;
  background: var(--genesys-light-grey);
  background: url("../../assets/images/landingPageSectionTwoImg1.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.section-2-images div:nth-of-type(2) {
  min-width: 147.61px;
  height: 232.38px;
  background: var(--genesys-light-grey);
  background: url("../../assets/images/landingPageSectionTwoImg2.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.section-2-images div:nth-of-type(3) {
  min-width: 146.66px;
  height: 138.57px;
  background: var(--genesys-light-grey);
  background: url("../../assets/images/landingPageSectionTwoImg3.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.section-2-images div:nth-of-type(4) {
  min-width: 147.61px;
  height: 198.09px;
  background: var(--genesys-light-grey);
  background: url("../../assets/images/landingPageSectionTwoImg4.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.section-3 div:nth-of-type(1) p {
  color: var(--genesys-grey);
  line-height: 25.89px;
  font-size: 1rem;
  margin-top: -10px;
}

.blog-card-container {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 50px;
}

.blog-card-container > div:not(:last-of-type) {
  margin-right: 0px;
  margin-bottom: 25px;
}

.blog .button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog .button-container button svg {
  margin-left: 5px;
}

.section-3-b > div {
  height: 530px;
  width: 100%;
}

.section-3-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 1fr);
  grid-row-gap: 20px;
}

.section-3-grid > div:nth-of-type(1) {
  display: flex;
  align-items: center;
}

.section-3-grid > div:nth-of-type(1) > div {
  height: 250px;
  margin-top: 40px;
}

.section-3-grid > div:nth-of-type(2) > div,
.section-3-grid > div:nth-of-type(3) > div {
  height: 250px;
}

.blog {
  margin-top: 330px;
}

@media screen and (min-width: 768px) {
  .container section {
    margin-bottom: 80px;
  }

  .section-3-grid > div:nth-of-type(1) > div {
    height: 350px;
    margin-top: 0px;
  }

  .section-3-grid > div:nth-of-type(2) > div,
  .section-3-grid > div:nth-of-type(3) > div {
    height: 100%;
  }

  .section-2-images div:nth-of-type(1) {
    width: 206px;
    height: 237px;
  }

  .section-2-images div:nth-of-type(2) {
    width: 206px;
    height: 325px;
  }

  .section-2-images div:nth-of-type(3) {
    width: 205.33px;
    height: 194px;
  }

  .section-2-images div:nth-of-type(4) {
    width: 137.33px;
    height: 277.33px;
  }

  .section-3 div:nth-of-type(1) p {
    width: 60%;
    margin-top: 0px;
  }

  .section-3-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1.3fr 1fr;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
  }

  .section-3-grid > div:nth-of-type(1) {
    grid-area: 1 / 1 / 3 / 2;
  }

  .blog {
    margin-top: 0px;
  }

  .blog-card-container {
    flex-direction: row;
  }
}

@media screen and (min-width: 992px) {
  .container section {
    margin-bottom: 100px;
  }

  .section-2-images {
    overflow-x: visible;
  }

  .section-2-images div:nth-of-type(1) {
    width: 310px;
    height: 356px;
  }

  .section-2-images div:nth-of-type(2) {
    width: 310px;
    height: 488px;
  }

  .section-2-images div:nth-of-type(3) {
    width: 308px;
    height: 291px;
  }

  .section-2-images div:nth-of-type(4) {
    width: 310px;
    height: 416px;
  }

  .section-3 div:nth-of-type(1) p {
    margin-right: 100px;
    margin-left: 100px;
    width: 20%;
  }

  .section-3-b {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .section-3-b > div {
    height: 530px;
    width: 70%;
  }

  .section-3-grid {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  .blog-card-container {
    flex-wrap: nowrap;
  }

  .blog-card-container > div:not(:last-of-type) {
    margin-right: 25px;
    margin-bottom: 0px;
  }
}
