.wrap {
    background: #111920 !important;
    border-radius: 210.5px;
    font-family: 'Segoe UI';
    font-style: normal;
    color: #FEFEFE;
    border-style: none;
    width: 100%;
    justify-content: center;
    cursor: pointer;
}