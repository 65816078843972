.container {
  position: fixed;
  top: 0;
  left: 10px;
  width: 300px;
  height: 60px;
  z-index: 99999;
  margin: 20px;
  padding: 16px 25px 10px 25px;
  border-radius: 5px;
  background-color: #fff;
  color: #000;
  font-size: 14px;
  animation: slidein 1.5s linear;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  font-family: var(--genesys-nunito);
}

.container svg {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.container svg.checked {
  fill: rgb(14, 172, 14);
}

.container svg.error {
  fill: #f00;
}

.container svg.warning {
  fill: rgb(226, 226, 62);
}

.container svg .container span:nth-of-type(2) {
  font-weight: 700;
}

svg.close {
  position: absolute;
  top: 18px;
  right: 15px;
  width: 15px;
  height: 15px;
  cursor: pointer;
  stroke: #000;
}

.line {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 6px;
  width: 100%;
  background: rgb(14, 172, 14);
  animation: lineout 4s linear;
}

.line-error {
  background-color: #f00;
}

.line-warning {
  background: yellow;
}

@keyframes slidein {
  0% {
    margin-right: -100%;
  }

  100% {
    margin: 20px;
  }
}

@keyframes lineout {
  0% {
    width: 100%;
  }

  25% {
    width: 75%;
  }

  50% {
    width: 50%;
  }

  100% {
    width: 0%;
  }
}

@media screen and (max-width: 500px) {
  .container {
    right: 5px;
    width: 260px;
    margin: 5px 0px;
    font-size: 12px;
  }

  @keyframes slidein {
    0% {
      margin-right: -100%;
    }

    100% {
      margin: 5px 0px;
    }
  }
}

@media screen and (max-width: 275px) {
  .container {
    width: 100;
  }
}
