.codeVilleCompWrapper {
  padding: 50px;
}

.codeVilleCompWrapper > h2 {
  font-size: 20px;
  line-height: 135%;
  color: var(--codeville-color-space-cadet);
  margin-bottom: 16px;
  margin-top: 30px;
  text-transform: uppercase;
}
