.container h1 {
  color: var(--genesys-light-grey);
  font-family: var(--genesys-font-nunito);
  font-size: 0.9375rem;
  font-weight: 600;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.container h2 {
  font-size: 2.125rem;
  font-weight: 900;
  margin-bottom: 30px;
  color: var(--genesys-blue);
}

.container p {
  color: var(--genesys-grey);
  line-height: 25.89px;
  font-size: 1rem;
  margin-bottom: 20px;
}

.container button {
  display: flex;
  align-items: center;
  margin: auto;
}

.container button svg {
  margin-left: 8px;
}

.container > div {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

@media screen and (min-width: 768px) {
  .container h2 {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 992px) {
  .container h2 {
    font-size: 2.8rem;
  }

  .container {
    margin-left: 70px;
    margin-right: 70px;
  }

  .container > div {
    flex-direction: row;
  }

  .container button {
    margin: 0px;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    margin-left: 100px;
    margin-right: 100px;
  }

  .container h2 {
    font-size: 3.75rem;
  }

  .container > div div:nth-of-type(2) {
    width: 45%;
  }
}
