.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--codeville-btn-border-radius);
  border: none;
  padding: 17px 24px;
  cursor: pointer;
  font-size: 14px;
  line-height: 16px;
}

.button a {
  text-decoration: none;
  width: 100%;
  height: 100%;
}

/* Primary Button Class */
.button.primary,
.button.primary a {
  font-weight: 600;
  color: var(--codeville-color-white);
  background: var(--codeville-btn-primary-bg);
  transition: all 0.3s ease-in-out;
}

.button.primary:hover,
.button.primary:hover a {
  box-shadow: 0px 4px 10px rgba(239, 41, 71, 0.68);
}

.button.primary:disabled,
.button.primary:disabled a {
  cursor: not-allowed;
  background: var(--codeville-btn-primary-disabled-bg);
  color: var(--codeville-btn-primary-disabled-color);
}

.button.primary:disabled:hover {
  box-shadow: none;
}

/* Primary Button Class */
.button.secondary,
.button.secondary a {
  font-weight: 600;
  color: #596275;
  background: var(--codeville-color-white);
  border: 1px solid #596275;
}

.button.secondary:hover,
.button.secondary:hover a {
  border: 1px solid #e13f29;
  background: -webkit-linear-gradient(273.75deg, #e13f29 -1.55%, #fb7a68 -1.54%, #ec1840 104.27%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.button.secondary:hover a,
.button.secondary a {
  border: none;
}

.button.secondary:disabled {
  cursor: not-allowed;
  background: var(--codeville-btn-secondary-disabled-bg);
  color: var(--codeville-btn-secondary-disabled-color);
}

.button.secondary:disabled:hover {
  box-shadow: none;
}

/* Button Icon */
.buttonIcon {
  margin-left: 13px;
  display: flex;
  align-items: center;
}

.button.secondary:hover .buttonIcon svg path {
  fill: #e13f29;
}
