.CUMain {
    width: 100%;
    padding: 0 100px;
}
.mainForm {
    width: 100%;
    margin: 96px 0px;
}
.formWrapper {
    width: 100%; /*1220px */
    margin: 0px 0px 40px;
}
.formRow {
    display: flex;
    margin: auto;
}
.formCol1 {
    min-width: 48.36065574%;
    margin: 0px 10px 0px 0px;
}
.formCol2 {
    min-width: 48.36065574%;
    margin: 0px 0px 0px 10px;
}
.inputField {
    margin: 0px 0px 17px 0px;
}
.contactDetailWrapper {
    width: 100%;
    margin: 100px 0px;
}
.contactDetailRow {
    width: 90%;
    margin: auto;
    display: flex;
}
.contactDetailCol {
    width: 33.3%;
}
.contactDetailText {
    font-family: var(--genesys-font-roboto);
    font-style: normal;
    font-weight: normal;
    font-size: 1.25rem;
    line-height: 110.19%;
    letter-spacing: 0.5px;
    color: #7D7D7D;
    margin: 3px 0px;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: wrap;
}
.contactHeader {
    font-family: var(--genesys-font-roboto);
    font-style: normal;
    font-weight: 900;
    font-size: 2.125rem;
    line-height: 90.19%;
    letter-spacing: 0.25px;
    color: #17255A;
    padding: 0px 0px 0px 18px;
}
.contactUsBannerWrapper {
    width: 63%;
}
.contactUsBanner{
    font-family: var(--genesys-font-roboto);
    font-style: normal;
    font-weight: 900;
    font-size: 3.75rem;
    line-height: 110.19%;
    color: #17255A;
}
.contactUsSmallHeader {
    font-family: var(--genesys-font-nunito);
    font-style: normal;
    font-weight: 600;
    font-size: 0.9375rem;
    line-height: 110.19%;
    letter-spacing: 1.25px;
    color: #CECDCD;
    margin: 15px 0px;
}
.contactHeaderWrapper {
    display: flex;
    margin: 20px 0px;
    width: 100%;
}
.icon {
    width: 26.67px;
    height: 26.67px;
}
.contactUsMapContainer {
    width: 100%;
    height: 460px;
}
.img7 {
    max-width: 100%;
}
.contactUsMap {
    width: 100%;
    border:0;
    height: 100%;
}
.contactUsBrandContainer {
    width: 70%;
    margin: auto;
    background-color: #f2f2f2;
}
.cUBrandHeader {
    font-family: var(--genesys-font-roboto);
    font-size: 3.75rem;
    font-style: normal;
    font-weight: 900;
    color: var(--genesys-blue);
    text-align: center;
    width: 100%;
    line-height: 110.19%;
    letter-spacing: -0.5px;
    margin: 48px 0px;
}
@media screen and (max-width: 560px) {
    .contactUsMapContainer {
        width: 114%;
        height: inherit;
        margin: 0px -9%;
    }
    .contactUsBrandContainer {
        width: 114%;
        margin: 0px -9%;
        height: inherit;
    }
    .contactHeaderWrapper {
        margin: 20px 0px;
    }
    .contactUsBanner {
        font-size: 2rem;   
    }
    .CUMain {
        width: 100%;
        padding: 0px 0px !important;
    }
    .mainForm {
        margin: 50px 0px;
        width: 100%;
    }
    .formRow, .contactDetailRow {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
    .contactDetailCol {
        width: 100%;
        margin-bottom: 10px;
    }
    .formCol1 {
        width: 100%;
        margin: 0px 0px 10px 0px;
    }
    .formCol2 {
        width: 100%;
        margin-left: 0px;
    }
    .contactDetailCol {
        width: 100%;
        margin: 10px 0px;
    }
    .contactUsBannerWrapper {
        width: 100%;
    }
    .contactDetailWrapper {
        margin: 0px;
    }
    .contactButton {
        font-size: 0.875rem;
    }
    .contactHeader {
        font-family: Roboto;
        font-style: normal;
        font-weight: 900;
        font-size: 1.4rem;
        line-height: 110.19%;
        color: #17255A;
    }
    .contactDetailText {
        font-family: var(--genesys-font-roboto);
        font-style: normal;
        font-weight: normal;
        font-size: 0.9rem;
        line-height: 26px;
        color: #7D7D7D;
    }
   
}
@media screen and (max-width: 769px) {
    .CUMain {
        width: 100%;
        padding: 0 30px;
    }
    .contactUsBanner {
        font-size: 2.4rem;   
    }
    .contactDetailRow {
        width: 100%;
        margin: auto;
        display: flex;
    }
    .contactDetailText {
        font-size: 0.9rem;
    }
    .contactHeader {
        font-size: 1.4rem;
    }
    .mainForm {
        margin: 50px 0px;
        width: 100%;
    }
    .contactDetailWrapper {
        margin: 30px 0px;
    }
    .cUBrandHeader {
        margin: 50px 0px;

        font-family: 'Roboto';
        font-weight: 900;
        font-size: 2.125rem;
        line-height: 110.19%;
        letter-spacing: 0.25px;
    }
} 