.popContainers {
  width: 100%;
  height: 100%;
  position: relative;
  background: #000;
}

.popImage {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: fill;
}

.popImage:hover {
  transform: scale(1.1);
}

.container {
  position: fixed;
  top: 15%;
  right: 30%;
  display: flex;
  justify-content: center;
  width: 700px;
  height: 600px;
  z-index: 500;
}

.content {
  border-radius: 24px;
  height: 600px;
  width: 900px;
  display: flex;
  justify-content: center;
}

.cancel {
  position: absolute;
  top: 2%;
  right: 2%;
  width: 35px;
  height: 35px;
  cursor: pointer;
  transition: 0.3s;
  stroke: black;
  fill: black;
  z-index: 1000;
}

.cancel:hover {
  transform: scale(1.55);
}

@media only screen and (max-width: 768px) {
  .container {
    width: 100%;
    height: 400px;
    top: 20%;
    right: 0;
  }

  .cancel {
    width: 20px;
    height: 20px;
  }

  .content {
    width: 100%;
    padding: 20px;
    height: 100%;
  }
  .popImage {
    object-fit: fill;
  }

}