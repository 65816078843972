.headWrapper {
    width: 100%;
}
.header {
    font-family: var(--genesys-font-roboto);
    font-size: 3.75rem;
    font-style: normal;
    font-weight: 900;
    line-height: 66px;
    color: var(--genesys-blue);
    text-align: center;
    margin: 112px 0;
    width: 100%;
}


@media screen and (max-width: 769px) {
    .header{
        font-size: 2rem;
        margin: 30px 0px 5px ;
        line-height: 110.19%;
        letter-spacing: 0.25px;
        text-align: left; 
    }
    .headWrapper {
        width: 100%;
    }
}

@media screen and (max-width: 576px) {
    .header{
        margin: 34px 0px 47px ;
        }
}