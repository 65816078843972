.container {
  background: var(--learnable-lighter-orange);
  padding: 3rem 0rem;
}

.big-sub-container {
  display: none;
}

.big-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.small-grid {
  display: grid;
  justify-content: center;
}

.big-grid > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.big-grid > div:nth-of-type(2) > div:nth-of-type(2) {
  position: relative;
  margin-top: 4rem;
  z-index: 2;
}

.middleGridItem {
  position: relative;
}

.how-it-works-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8rem;
  height: 8rem;
  background: var(--learnable-dark-blue);
  color: var(--genesys-white);
  font-weight: 700;
  font-size: 1.15rem;
  padding: 2rem 1rem;
  border-radius: 50%;
  text-align: center;
  margin: 0 auto;
}

.big-circle {
  position: relative;
  width: 16.25rem;
  height: 16.25rem;
  max-width: 100vw;
  background: var(--genesys-white);
  border-radius: 50%;
  border: 1px solid var(--learnable-light-orange);
  text-align: center;
  padding: 1rem 2rem;
  z-index: 4;
}

.circleHolder {
  display: grid;
}

.circleHolder:not(:last-of-type):after {
  display: inline-block;
  margin: 0 auto;
  content: "";
  width: 0.1px;
  height: 3rem;
  background: var(--learnable-light-orange);
  border: 1px solid var(--learnable-light-orange);
}

.curve {
  display: none;
  position: absolute;
  z-index: 0;
}

.left-curve {
  top: 100px;
  left: -140px;
  transform: rotate(5deg);
}

.right-curve {
  top: 100px;
  right: -140px;
  transform: rotate(5deg);
}

.circle-number {
  display: inline-block;
  background: var(--learnable-dark-orange);
  color: var(--genesys-white);
  font-size: 3rem;
  font-weight: 700;
  padding: 0.4rem 1.6rem;
  border-radius: 50%;
  margin-bottom: 1.4rem;
}

.circle-number > * {
  vertical-align: middle;
}

.big-circle h6 {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: var(--learnable-dark-blue);
}

.big-circle p {
  font-size: 0.625rem;
  line-height: 1.1rem;
  color: var(--learnable-text-color);
  font-weight: 300;
}

@media screen and (min-width: 768px) {
  .container {
    padding: 4rem 0rem;
  }
}

@media screen and (min-width: 992px) {
  .big-sub-container {
    display: block;
  }

  .small-sub-container {
    display: none;
  }

  .curve {
    display: block;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    padding: 4.5rem 9rem;
  }
}
