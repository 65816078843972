.experienceContainer {
  position: relative;
  background-image: url("../../../../assets/images/codeville/heroExperienceBg.png"),
    url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='16' height='16' rx='8' fill='%23FDD19C'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat, no-repeat;
  background-size: 100% 100%, 16px 16px;
  background-position: 100% 100%, left 43px top 64px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 88px;
  padding-bottom: 68px;
}

.experienceContainer h6 {
  color: #56c2df;
  text-align: center;
}

.experienceContainer h2 {
  font-weight: 600;
  font-size: 48px;
  line-height: 110.19%;
  margin-bottom: 16px;
  text-align: center;
  color: var(--codeville-color-space-cadet);
}

.experienceContainer p {
  font-weight: 300;
  font-size: 16px;
  line-height: 145%;
  max-width: 617px;
  text-align: center;
}

.experienceContainer::after {
  content: "";
  position: absolute;
  background-image: url("data:image/svg+xml,%3Csvg width='38' height='38' viewBox='0 0 38 38' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='38' height='38' rx='19' fill='%23FDD19C'/%3E%3C/svg%3E%0A");
}

.experienceHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.experienceVideo {
  width: 100%;
  max-width: 1090px;
  height: fit-content;
  display: flex;
  background-color: var(--codeville-color-white);
  padding: 70px;
  opacity: 0.8;
  box-shadow: 0px 23.7671px 49.2947px #dfe0e0;
  border-radius: 32px;
  margin-top: 64px;
  margin-left: 16px;
  margin-right: 16px;
}

.experienceVideo img {
  width: 100%;
}

@media screen and (max-width: 750px) {
  .experienceVideo > div {
    height: 338.16px !important;
  }
  .experienceVideo {
    height: calc(338px + 44px);
  }
}

@media screen and (max-width: 700px) {
  .experienceVideo {
    padding: 22px;
  }

  .experienceContainer h6 {
    margin-bottom: 4px;
  }

  .experienceContainer h2 {
    font-size: 24px;
  }

  .experienceContainer p {
    font-size: 14px;
    max-width: 338px;
  }
}

@media screen and (max-width: 465px) {
  .experienceVideo > div {
    height: 260px !important;
  }

  .experienceVideo {
    height: calc(260px + 44px);
  }

  .experienceContainer {
    padding: 16px;
    padding-bottom: 89px;
    padding-top: 84px;
  }
}
