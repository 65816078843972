.container {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  z-index: 500;
}

.content {
  position: relative;
  background: #fff;
  border-radius: 24px;
  min-height: 300px;
  max-width: 85%;
  transition: all 1000ms;
}

.cancel {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 15px;
  height: 15px;
  cursor: pointer;
  transition: 0.3s;
}

.cancel:hover {
  transform: scale(1.55);
}
