.container {
  padding-bottom: 3rem;
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

.grid > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-container p {
  margin-bottom: 2rem;
}

.image-container {
  position: relative;
  background-position: center;
  background-size: cover;
}

.image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-container-1 {
  width: 90%;
  height: 287.7px;
}

.image-container-2 {
  width: 90%;
  height: 324.72px;
}

.image-container-3 {
  width: 90%;
  height: 287.7px;
}

@media screen and (min-width: 768px) {
  .container {
    padding-bottom: 4rem;
  }

  .image-container-1 {
    width: 310px;
    height: 616px;
  }

  .image-container-2 {
    width: 616px;
    height: 595px;
  }

  .image-container-3 {
    width: 310px;
    height: 616px;
  }
}

@media screen and (min-width: 1200px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0rem;
  }

  .grid > div {
    align-items: flex-start;
  }

  .grid > div:nth-of-type(1),
  .grid > div:nth-of-type(3) {
    margin-top: 12rem;
  }

  .grid > div:nth-of-type(1) {
    justify-content: flex-start;
  }
  .grid > div:nth-of-type(3) {
    justify-content: flex-end;
  }

  .text-container {
    width: 65%;
    margin: 0 auto;
  }
}
