.gallery {
  width: 100%;
}

.galleryRow {
  display: flex;
  margin: auto;
  align-items: center;
  width: 100%;
}
.galleryCol1 {
  width: 50%; /* 610/ 1220 */
  padding-right: 1%;
}
.gallery2HeaderWrapper {
  margin: 50px 0px;
  display: flex;
  flex-direction: column;
  justify-self: center;
}
.galleryCol2 {
  Width: 50%;
  padding-left: 1%;
}
.img1, .img2, .img3, .img4, .img5 {
  max-width: 100%;
  height: auto;
}
.gallery2 {
  width: 100%;    /* 1220px */
  font-style: normal;
}
.gallery2HeaderWrapper {
  margin: 70px ;
}

.flexRow {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.gallery3 {
  margin-bottom: 50px;
}
.gallery2largeHeading  {
  font-family: Roboto;
  font-weight: 900;
  font-size: 60px;
  color: var(--genesys-blue);
}

.gallery2Col2 {
  margin: auto;
  position: relative;
  width: 256.5px;
  height: 315px;
  margin-bottom: 40px;
  background-color: #f2f2f2;
}

.imgProfile {
  width: 100%;
  height: inherit;
  object-fit: cover;
  object-position: center;
}

.gallery2smallerHeading {
  font-family: var(--genesys-font-nunito);
  font-weight: 600;
  font-size: 15px;
  line-height: 110.19%;
  text-transform: uppercase;
  color: var(--genesys-mild-grey);
  margin-bottom: 15px;
}
.gallery2Text2 {
  background: linear-gradient(91.32deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.1) 98.11%);
  backdrop-filter: blur(50px);
  position: absolute;
  bottom: 0px;
  padding-bottom: 6px;
  left: 0;
  right: 0;
}

.gallery2Text2 > h6  {
  font-family: Roboto;
  font-weight: 900;
  font-size: 20px;
  line-height: 110.19%;
  color: var(--genesys-purple);
  margin: 15px 15px 0px;
}

.profile_container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  min-height: 400px;
  margin: 30px 0px;
}

.gallery2Row {
  display: flex;
  width: 100%;
}
.gallery2Col {
  margin: auto;
  width: 31.3%;
  position: relative;
  background-color: #f2f2f2;
}

.gallery2Text {
  background: linear-gradient(91.32deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.1) 98.11%);
  backdrop-filter: blur(50px);
  height: 74px;
  width: 100%;
  position: absolute;
  top: 298px; /* 372 - 74 */
}

.gallery2Text > h6  {
  font-family: Roboto;
  font-weight: 900;
  font-size: 20px;
  line-height: 110.19%;
  color: var(--genesys-purple);
  margin: 15px 15px 0px;
}

.flexProfile {
  display: flex;
  margin-top: -30px;
  justify-content: center;
  align-items: center;
  width: 50%;
  flex-wrap: wrap !important;
}

.gallery2Paragraph {
  font-family: Nunito;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: var(--genesys-purple);
  margin: 0px 15px;
}

@media screen and (max-width: 426px) {
  .img3, .img4, .img5 {
    width: inherit; 
  }
  .gallery2Col {
    width: 100%;
    margin-bottom: 55px;
  }
  .gallery2Row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .gallery .gallery2 {
    width: 100%;
    margin: 0px;
  }
  .gallery2Text {
   width: inherit;
  }

  .gallery2Col2 {
    width: 100%;
    height: auto;
  }
  
  .flexProfile {
    width: 100%;
    flex-direction: column;
  }
  .gallery2largeHeading {
    font-size: 34px;
    line-height: 110.19% ;
    margin: 10px 0px; 
  }
  .gallery2HeaderWrapper {
    margin: 50px 0px;
}
  .gallery2Paragraph {
    font-size: 0.75rem;
  }
  .gallery2Text > h6 {
    font-size: 16px;
  }
}

@media screen and (min-width: 427px) and (max-width: 996px ) {
  .gallery2Col {
    height: auto;
    position: relative;
  }

  .flexProfile {
    width: 100%;
  }

  .gallery2Text {
    position: absolute;
    top: 165px;
    bottom: 0px;
  }
  .gallery2Paragraph {
    font-size: 12px;
    line-height: 15px;
  }
} 