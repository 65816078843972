.children {
    overflow: scroll;
    margin: 74px 0 389px 0;
}

@media screen and (max-width: 1024px) {
    .children {
        margin-block-end: 342px;
    }
}

@media screen and (max-width: 768px) {
    .children {
        margin: 0 0 324px 0;
        padding-block-start: 66px;
    }
}

@media screen and (max-width: 425px) {
    .children {
        margin-block-end: 0px;
        padding-block-start: 66px;
    }
}