.container {
  position: relative;
  width: 100%;
  height: 250px;
  font-family: var(--genesys-font-roboto);
  box-shadow: 0px 0px 8px 0px rgba(229, 229, 229, 0.5);
  padding: 25px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.container h1 {
  color: var(--genesys-black);
  font-size: 24px;
  font-weight: 600;
  line-height: 26.45px;
  margin-bottom: 15px;
  text-transform: capitalize;
}

.container p {
  color: var(--genesys-grey);
  font-size: 14px;
  line-height: 21.09px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.container a {
  display: flex;
  color: var(--genesys-red);
  cursor: pointer;
  font-size: 12.22px;
  font-weight: 600;
  font-family: var(--genesys-font-nunito);
  text-transform: uppercase;
  text-decoration: underline;
}

.container a span {
  margin-left: 10px;
}

.link {
  position: absolute;
  bottom: 30px;
}

@media screen and (min-width: 768px) {
  .container {
    height: 360px;
    padding: 20px;
    margin-left: 10px;
  }

  .container h1 {
    font-size: 28px;
    line-height: 30.85px;
    margin-bottom: 18px;
  }

  .container p {
    font-size: 16px;
    line-height: 25.89px;
    white-space: normal;
  }

  .container a {
    font-size: 15px;
  }

  .link svg {
    width: 24px;
    height: 24px;
  }
}

@media screen and (min-width: 1024px) {
  .container {
    height: 345px;
    padding: 40px;
    margin-left: 0px;
  }
}
