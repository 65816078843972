.container h1 {
  color: var(--genesys-light-grey);
  font-family: var(--genesys-font-nunito);
  text-transform: uppercase;
  font-size: 0.9375rem;
  font-weight: 600;
  margin-bottom: 10px;
}

.container h2 {
  text-transform: capitalize;
  font-size: 2.125rem;
  font-weight: 900;
  margin-bottom: 20px;
  color: var(--genesys-blue);
}

.container p {
  color: var(--genesys-grey);
  line-height: 26px;
  font-size: 16px;
  text-align: justify;
}

.flex-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

@media screen and (min-width: 768px) {
  .container h2 {
    font-size: 2.4rem;
  }

  .container p {
    margin-bottom: 0px;
  }
}

@media screen and (min-width: 992px) {
  .container {
    margin-left: 70px;
    margin-right: 70px;
  }

  .container h2 {
    font-size: 2.8rem;
    margin-bottom: 26px;
  }

  .container p {
    margin-bottom: 15px;
  }

  .flex-container {
    flex-direction: row;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    margin-left: 100px;
    margin-right: 100px;
  }

  .container h2 {
    font-size: 3.75rem;
    margin-bottom: 30px;
  }

  .container p {
    margin-bottom: 20px;
  }

  .flex-container div:nth-of-type(2) {
    width: 55%;
    padding-top: 12px;
  }
}
