.container {
  display: inline-block;
  background: none;
  border: 1px solid var(--genesys-light-grey);
  border-radius: 8px;
  cursor: pointer;
  color: var(--genesys-red);
  font-family: var(--genesys-font-nunito);
  font-weight: 600;
  font-size: 1rem;
  padding: 11px 20px;
  transition: 0.3s;
}

.container:hover {
  transform: scale(1.05);
}

.button-filled {
  background: var(--genesys-red);
  color: #fff;
}

.container:disabled {
  background: var(--genesys-grey);
  cursor: not-allowed;
}
