.learningActivities {
  padding: 4rem 0;
  max-width: 1200px;
  margin: auto;
  position: relative;
  background-color: #ffffff;
  display: grid;
  gap: 3rem;
}

.learningActivities_header {
  display: grid;
  gap: 1.5rem;
  text-align: center;
  max-width: 800px;
  margin: auto;
  padding: 0 1.5rem;
}

.learningActivities_header span {
  background: linear-gradient(273.75deg, #e13f29 -1.55%, #fb7a68 -1.54%, #ec1840 104.27%);
  background-clip: text;
  -webkit-background-clip: text;
  color: rgb(0 0 0 / 20%);
  font-size: 16px;
  text-transform: uppercase;
}

.learningActivities_header h1 {
  font-size: 32px;
  font-weight: 600;
  color: #17255a;
  text-transform: capitalize;
}

.learningActivities_content {
  display: grid;
  gap: 2rem;
}

.slideItem {
  width: 80% !important;
}

.slideIcon{
 position: absolute; 
 top: 10px;
 left: 20px;
 width: 100px;
}

.slideItem_container{
  position: relative;
  height: 30rem;
  width: 100%;
  background: #E6E6E6;
  border: .5px solid #EEEEEE;
  border-radius: 20px;
  overflow: hidden;
}

.slideItem_imageContainer{
  height: 100%;
}

.slideItem_imageContainer img{
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: top;
}

.slideItem_textContainer {
  position: absolute;
  bottom: 30px;
  left: 30px;
  padding: 10px;
}

.slideItem_textContainer p {
  max-width: 25rem;
  background-color: #ffffff;
  border: .5px solid #EEEEEE;
  border-radius: 20px;
  padding: 10px 15px;
  font-size: 16px;
  font-weight: 300;
}

.slide_footer{
  display: flex;
  justify-content: space-between;
  gap: 20px;
  max-width: 1200px;
  width: 100%;
  margin: auto;
  padding: 0 1.5rem;
}

.slideCards{
  display: inline-flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  border: 1px solid #596275;
  border-radius: 50rem;
}

.slideCards span{
  color: #596275;
  font-size: 14px;
  font-weight: 600;
}

.slideButtons{
  display: inline-flex;
  align-items: center;
  gap: 10px;
}

.slideButtons button{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  /* padding: 20px; */
  background: #ffffff;
  color: #596275;
  border: 1px solid;
  border-radius: 50rem;
  cursor: pointer;
}

.slideButtons button:hover{
  color: #e13f29;
}

.slideButtons button:disabled{
  opacity: 0.5;
  cursor: not-allowed;
}

.slideButtons button:disabled:hover{
  color: #596275;
}


/*************** Media Queries ****************/
@media screen and (max-width: 681px) {
  .slideItem_textContainer {
    left: 0;
  }
  .slideButtons {
    display: none;
  }
}