.tC {
    border: 1px solid #EEEEEE;
    border-radius: 10px;
    height: 325px;
    width: 100%;
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-block-end: 15px;
}

.tC h5 {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin-block: 0;
    color: #191919;
    padding-block: 18px 8px;
}

.tC>div {
    display: flex;
    align-items: center;
}

.tC img {
    max-width: 100%;
    object-fit: contain;
    max-height: auto;
}

.greyText {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #BABABA;
    margin: 0;
}

.blueText {
    color: #009CEB
}


@media screen and (max-width: 425px) {
    .tC {
        height: 325px;
        width: 100%;
    }

    .blueText {
        margin-inline-start: 5px;
    }
}