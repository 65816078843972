.accordionContainer {
  background-color: var(--codeville-color-white);
  width: 100%;
  max-width: 760px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  position: relative;
  cursor: pointer;
}

.accordionContainer.open {
  box-shadow: 0px 23.7671px 49.2947px rgba(96, 96, 96, 0.14);
}

.accordionContainer p {
  font-weight: 400 !important;
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: -1px;
  color: #596275;
  position: relative;
  min-height: 61px;
}

.toggleIcon {
  cursor: pointer;
}

.accordionAnswer {
  background-color: var(--codeville-color-white);
  font-weight: 300;
  font-size: 16px;
  line-height: 145%;
  color: #7c7c7c;
  position: relative;
  display: none;
  animation: slideAwayFromBottom 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation-fill-mode: backwards;
}

.accordionAnswerOpen {
  display: flex;
  height: fit-content;
  width: 100%;
  padding: 16px 0px 32px 0px;
  animation: slideInFromBottom 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  padding: 16px 0px 32px 0px;
  animation-fill-mode: forwards;
}

@keyframes slideInFromBottom {
  0% {
    bottom: -100%;
    opacity: 0;
  }
  100% {
    opacity: 1;
    bottom: 0%;
  }
}

@keyframes slideAwayFromBottom {
  0% {
    opacity: 1;
    bottom: 0%;
  }
  100% {
    bottom: -100%;
    opacity: 0;
    display: none;
    height: 0px !important;
    padding: 0px;
  }
}

.accordionAnswer::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 1px;
  background-color: #eeeeee;
}

.accordionListAnswer.accordionAnswer {
  padding-left: 24px;
  flex-direction: column;
}

@media screen and (max-width: 450px) {
  .accordionContainer p {
    font-size: 16px;
  }

  .toggleIcon {
    right: 0px;
  }

  .accordionAnswer {
    font-size: 14px;
  }
}
