.sparksHeaderWrapper {
    width: 83%;
    margin: auto;
    padding: 50px 0px 0px 0px;
}.sparksHeaderWrapper > h1{
    font-family: var(--genesys-font-roboto);
    font-size: 60px;
    font-style: normal;
    font-weight: 900;
    line-height: 70px;
    letter-spacing: -0.5px;
    color: #17255A;
}
.sparksHeaderWrapper > p {
    font-family: var(--genesys-font-roboto);
    font-size: 17px;
    font-style: norm5;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0px;
    color: #7D7D7D;
    width: 43%;
}
  
@media screen and (max-width: 768px) {
    .sparksHeaderWrapper > h1 {
        font-size: 34px;
        width: 100%;
    }
    .sparksHeaderWrapper > p {
        font-size: 17px;
        width: 100%;
    }
    .sparksHeaderWrapper {
        width: 100%;
    }
}

