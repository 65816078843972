.container section {
  margin-bottom: 55px;
}

.container section:nth-of-type(2) {
  margin-top: -30px;
}

.container section:nth-of-type(2) > div {
  display: flex;
  justify-content: space-between;
}

.container section:nth-of-type(2) > div > div {
  width: 49%;
}

.container section:nth-of-type(2) > div img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.container section:nth-of-type(2) > div > div:nth-of-type(2) {
  margin-top: 60px;
}

.container section:nth-of-type(3) > div p {
  font-family: var(--genesys-font-nunito);
  font-weight: 400;
  font-size: 16px;
  line-height: 27.51px;
  color: var(--genesys-grey);
  margin-bottom: 20px;
}

.container section:nth-of-type(4),
.container section:last-of-type {
  position: relative;
  background: var(--genesys-bg-light-grey);
  padding-bottom: 60px;
}

.container section:nth-of-type(4)::before,
.container section:last-of-type:before {
  content: "";
  position: absolute;
  left: -16px;
  height: 100%;
  width: 90vw;
  background: var(--genesys-bg-light-grey);
}

.container section:nth-of-type(4)::after,
.container section:last-of-type::after {
  content: "";
  position: absolute;
  top: 0;
  right: -16px;
  height: 100%;
  width: 90vw;
  background: var(--genesys-bg-light-grey);
}

.container section:nth-of-type(4) > div,
.container section:last-of-type > div {
  position: relative;
  z-index: 4;
  padding: 80px 0px;
}

.wWAinto-container {
  display: flex;
  flex-direction: column;
  margin-top: -120px;
}

.wWAinto-container > div:nth-of-type(1) {
  margin-bottom: 100px;
}

.faq-container {
  display: flex;
  flex-direction: column;
}

.faq-container h2 {
  margin-bottom: 0px;
}

.faq-container > div:first-of-type {
  margin-bottom: 60px;
}

.contact-button-a {
  display: none;
  margin-top: 20px;
}

.contact-button-b {
  display: block;
  margin: 40px auto 0 auto;
}

@media screen and (min-width: 768px) {
  .container section {
    margin-bottom: 70px;
  }

  .container section:nth-of-type(2) > div > div {
    height: 260px;
  }

  .container section:nth-of-type(2) > div > div:nth-of-type(2) {
    margin-top: 90px;
  }

  .container section:nth-of-type(3) > div {
    width: 60%;
  }

  .container section:nth-of-type(4)::before,
  .container section:last-of-type:before {
    width: 100vw;
  }

  .container section:nth-of-type(4)::after,
  .container section:last-of-type::after {
    width: 100vw;
  }

  .wWAinto-container {
    justify-content: space-around;
    flex-direction: row;
  }

  .wWAinto-container > div:nth-of-type(1) {
    margin-bottom: 0px;
  }
}

@media screen and (min-width: 1200px) {
  .container section {
    margin-bottom: 90px;
  }

  .container section:nth-of-type(2) > div > div {
    height: 360px;
  }

  .container section:nth-of-type(2) > div > div:nth-of-type(2) {
    margin-top: 100px;
  }

  .container section:nth-of-type(4)::before,
  .container section:last-of-type:before {
    content: "";
    position: absolute;
    left: -300px;
    height: 100%;
    width: 100vw;
    background: var(--genesys-bg-light-grey);
  }

  .container section:nth-of-type(4)::after,
  .container section:last-of-type::after {
    content: "";
    position: absolute;
    top: 0;
    right: -300px;
    height: 100%;
    width: 100vw;
    background: var(--genesys-bg-light-grey);
  }

  .faq-container {
    display: flex;
    flex-direction: row;
  }

  .faq-container > div:first-of-type {
    width: 50%;
    margin-bottom: 0px;
  }

  .faq-container > div:last-of-type {
    width: 55%;
    margin-left: -40px;
  }

  .contact-button-a {
    display: block;
  }

  .contact-button-b {
    display: none;
  }
}
