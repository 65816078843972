.textarea {
    font-family: var(--genesys-font-roboto);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    padding: 12px;
    background: var(--genesys-white);
    border: 1px solid var(--genesys-light-grey);
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
}
::placeholder { /* Chrome, Firefox, Opera, Safari  */
    color: var(--genesys-light-grey);
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer */
    color: var(--genesys-light-grey);
}
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--genesys-light-grey);
}
.textareaWrapper :focus {
  outline: none;
}