.vanguard {
  padding: 4rem 1.5rem;
  max-width: 1200px;
  margin: auto;
  display: grid;
  gap: 3rem;
}

.vanguard_header {
  display: grid;
  gap: 2rem;
  text-align: center;
  max-width: 500px;
  margin: auto;
}

.vanguard_header h1 {
  font-size: 42px;
}

.vanguard_header p {
  font-size: 16px;
}

.vanguard_content {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  display: grid;
  gap: 2.5rem;
}

.vanguard_contentWrapper {
  display: grid;
  place-items: center;
  gap: 15px;
  width: 100%;
}

.vanguard_videoWrapper {
  width: 100%;
  max-width: 50rem;
  height: 30rem;
  padding: 20px;
  border: .5px solid #EEEEEE;
  border-radius: 20px;
  box-shadow: 0px 3px 5px 0px #00000024;
  overflow: hidden;
}

.vanguard_videoWrapper video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: 10px;
}

.testimonies {
  position: relative;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.03)),
    url("./../../assets/images/codeville/gallery/gray-white-pixelated.png");
  background-size: cover;
  background-position: bottom;
  background-attachment: fixed;
}

.testimoniesWrapper {
  display: grid;
  gap: 50px;
  max-width: 1200px;
  margin: auto;
  padding: 4rem 1.5rem;
}

.testimonies_header {
  display: grid;
  gap: 10px;
  text-align: center;
  margin: auto;
}

.testimonies_header h1 {
  font-size: 32px;
  font-weight: 600;
  text-transform: capitalize;
}

.testimonies_header span {
  font-size: 16px;
  color: #56c2df;
}

.testimonies_content {
  display: grid;
  gap: 50px;
}

.testimoniesContainer {
  position: relative;
  width: 100%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  align-items: stretch;
}

/* .testimoniesContainer::after{
  content: "";
  height: 15rem;
  width: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0));
  position: absolute;
  bottom: -95px;
  left: 0;
} */

.testimoniesRow {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.testimonyItem {
  display: grid;
  gap: 15px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 20px;
}

.testimonyHeader {
  display: grid;
  gap: 10px;
}

.testimonyHeader h1 {
  font-size: 16px;
  font-weight: 600;
}

.testimonyContent {
  font-size: 14px;
  font-weight: 500;
}

.testimonies_loadMore {
  position: relative;
  display: inline-block;
  padding: 0.5rem 1rem;
  color: #596275;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  margin: auto;
  border: 1px solid #596275;
  border-radius: 2rem;
  cursor: pointer;
  z-index: 30;
}

.content_tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  margin: auto;
  background: #eeeeee;
  color: #596275;
  border-radius: 100px;
}

.content_tabs span {
  padding: 0.8rem 2rem;
  cursor: pointer;
  border-radius: 100px;
}

.content_tabs_activeTab {
  background: linear-gradient(273.75deg, #e13f29 -1.55%, #fb7a68 -1.54%, #ec1840 104.27%);
  color: #eeeeee;
}

/* List/Data not found */
.listNotFound {
  display: grid;
  padding: 20px 0;
  place-items: center;
  margin: auto;
}

.listNotFound_imageContainer {
  width: auto;
  height: 20rem;
  transform: translateX(15%);
}

.listNotFound_imageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.listNotFound p {
  font-size: 24px;
  font-weight: 500;
  color: #17255a;
}

/**************** Media Queries ***************/
@media screen and (max-width: 781px) {
  .testimoniesContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .testimoniesRow:nth-child(3){
    visibility: hidden;
  }
}

@media screen and (max-width: 481px) {
  .testimoniesContainer {
    display: grid;
    grid-template-columns: auto;
  }
  .testimoniesRow:nth-child(3){
    visibility: visible;
  }
}
