.container {
  position: relative;
  padding: 30px;
  background: var(--genesys-white);
  box-shadow: 0px 2px 8px 0px rgba(196, 196, 196, 0.2);
  cursor: pointer;
}

.container h1 {
  color: var(--genesys-blue);
  font-family: var(--genesys-font-roboto);
  font-weight: 900;
  font-size: 16px;
  line-height: 18px;
  max-width: 80%;
  margin-bottom: 15px;
}

.container p {
  color: var(--genesys-grey);
  font-family: var(--genesys-font-nunito);
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  max-width: 80%;
}

.container svg {
  position: absolute;
  right: 20px;
  top: 30px;
  width: 16px;
  height: 16px;
}

@media screen and (min-width: 768px) {
  .container h1 {
    font-size: 20px;
    line-height: 22px;
  }

  .container svg {
    position: absolute;
    right: 28px;
    top: 32px;
    width: 20px;
    height: 20px;
  }
}
