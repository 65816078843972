@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap");

/* Roboto Font */
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* Nunito Font Codeville*/
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* Open  Sans */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");

/* Codeville fonts */
@font-face {
  font-family: "MuseoSans";
  src: url("./fonts/museo-sans/museosans-100-webfont.woff");
  font-weight: 100;
}

@font-face {
  font-family: "MuseoSans";
  src: url("./fonts/museo-sans/museosans-300-webfont.woff");
  font-weight: 300;
}

@font-face {
  font-family: "MuseoSans";
  src: url("./fonts/museo-sans/museosans-500-webfont.woff");
  font-weight: 500;
}

@font-face {
  font-family: "MuseoSans";
  src: url("./fonts/museo-sans/museosans-700-webfont.woff");
  font-weight: 700;
}

@font-face {
  font-family: "MuseoSans";
  src: url("./fonts/museo-sans/museosans-900-webfont.woff");
  font-weight: 900;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('./fonts/Segoe-ui.ttf');
}

/* Global Varibles */
:root {
  /* Genesys */
  --genesys-white: #fff;
  --genesys-blue: #17255a;
  --genesys-bg-grey: #e3e3e3;
  --genesys-bg-light-grey: #f6f6f6;
  --genesys-grey: #7d7d7d;
  --genesys-light-grey: #cecdcd;
  --genesys-red: #e13f29;
  --genesys-black: #273043;
  --genesys-purple: #2d1e2f;
  --genesys-mild-grey: #c4c4c4;
  --genesys-light-white: #fcfbfb;
  --genesys-font-roboto: "Roboto", sans-serif;
  --genesys-font-nunito: "Nunito", sans-serif;

  /* Code ville*/
  --codeville-font: "MuseoSans", sans-serif;
  --codeville-text-color: #596275;
  --codeville-color-white: #fff;
  --codeville-color-space-cadet: #17255a;
  --codeville-color-sunset-orange: #e13f29;
  --codeville-btn-primary-bg: linear-gradient(273.75deg, #e13f29 -1.55%, #fb7a68 -1.54%, #ec1840 104.27%);
  --codeville-btn-primary-disabled-bg: #c4c4c4;
  --codeville-btn-primary-disabled-color: #f1f4f9;
  --codeville-btn-border-radius: 32px;
  --codeville-footer-bg: #f6f6f6;
  --codeville-link-hover: #e26857;
  --codeville-link-active: #e26857;

  /* Learnable */
  --learnable-font: "Open Sans", sans-serif;
  --learnable-green: #03cea4;
  --learnable-orange: #fa945a;
  --learnable-dark-orange: #f5772f;
  --learnable-light-orange: #fadac8;
  --learnable-lighter-orange: #fffcfa;
  --learnable-dark-purple: #2d1e2f;
  --learnable-yellow: #ffcc52;
  --learnable-gray: #b4bbcc;
  --learnable-dark-blue: #273043;
  --learnable-text-color: #596275;
  --learnbale-border-color: #eeeeee;
  --learnable-max-width: 1500px;
}

/* Codeville Section Wrapper */
.codevilleSectionWrapper {
  max-width: 1440px;
  margin: 0 auto;
}

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

body {
  position: absolute;
  width: 100%;
  min-height: 100vh;
  font-family: var(--genesys-font-roboto);
  overflow-x: hidden;
}

.container {
  max-width: 1300px;
  padding: 0 20px;
  margin: auto;
}

.learnable-container {
  max-width: 1350px;
  margin: 0 auto;
}

@media screen and (min-width: 320px) {
  .learnable-container {
    padding: 0 20px;
  }
}

.react-multi-carousel-list {
  overflow: visible !important;
}

@media screen and (min-width: 992px) {
  .subcontainer {
    margin-left: 70px;
    margin-right: 70px;
  }
}

@media screen and (min-width: 1200px) {
  .subcontainer {
    margin-left: 100px;
    margin-right: 100px;
  }

  .learnable-container {
    padding: 0 2%;
  }
}