.container {
  position: relative;
  width: 100%;
  height: 240px;
}

.text-container {
  position: absolute;
  background: #fff;
  box-shadow: 0px 2px 16px 0px rgba(196, 196, 196, 1);
  bottom: -55px;
  margin: 0 14%;
  padding: 25px;
}

.text-container h1 {
  color: var(--genesys-blue);
  font-weight: 500;
  font-family: var(--genesys-font-roboto);
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 15px;
}

.text-container p {
  color: var(--genesys-grey);
  font-weight: 500;
  font-family: var(--genesys-font-roboto);
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 15px;
}

.text-container a {
  color: var(--genesys-red);
  text-decoration: underline;
  font-weight: 600;
  font-family: var(--genesys-font-nunito);
  font-size: 14px;
  line-height: 16px;
}

@media screen and (min-width: 768px) {
  .container {
    width: 300px;
    height: 280px;
  }

  .text-container {
    bottom: -55px;
    right: -40px;
    width: 240px;
    margin: 0 0;
    padding: 25px;
  }

  .text-container h1 {
    font-size: 18px;
    line-height: 22px;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    width: 340px;
  }
}
