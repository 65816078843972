.sparksWrapper h1 ~ p {
  margin-top: 25px;
}
.sparksHeadWrapper {
  text-align: center;
  width: 75%;
  margin: auto;
}
.sparksHeadWrapper > h1 {
  font-family: var(--genesys-font-roboto);
  font-size: 60px;
  font-style: normal;
  font-weight: 900;
  color: #17255a;
  margin: 30px 0px;
}
.sparksHeadWrapper > p {
  font-family: var(--genesys-font-roboto);
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  color: #7d7d7d;
}
.sparksHeadWrapper > Button {
  margin: 50px 0px;
}
.sparksimgWrapper {
  width: 83%;
  margin: auto;
}
.sparksimg {
  width: 100%;
}
.sparksGoalCardBigWrapper {
  display: flex;
  /* margin-bottom: 100px; */
}
.sparksGoalCardWrapper {
  height: 500px;
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 50px 20px 0px 200px;
}
.customLink {
  text-decoration: none;
  color: var(--genesys-white);
}
.sparksGoalCardWrapper2 {
  height: 500px;
  width: 35%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.sparksGoalCard {
  width: 100%;
  height: 220px;
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(39, 48, 67, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
}
.sparksGoalCard > div {
  width: 45%;
}
.sparksGoalCard > div > svg {
  margin-bottom: 20px;
}
.sparksGoalCard > div p {
  font-family: var(--genesys-font-nunito);
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.5px;
}
.lpCardWrapper {
  display: flex;
  padding: 0% 0% 0% 27%;
}
.lpImg1 {
  width: 100%;
  object-fit: none;
  height: 222px;
}
.lpCard {
  width: 419px;
  width: 40%;
  margin: 30px 15px;
}
.lpCard1 {
  width: 419px;
  width: 40%;
  margin: -50px 5px;
}
.lpCard > div,
.lpCard1 > div {
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(39, 48, 67, 0.15);
  padding: 20px 25px;
}
.lpCard > div p,
.lpCard1 > div p {
  font-family: var(--genesys-font-nunito);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.4000000059604645px;
  width: 100%;
}
.lpCard > div h3,
.lpCard1 > div h3 {
  font-family: var(--genesys-font-roboto);
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 110.19%;
  letter-spacing: 0.5px;
  color: #17255a;
  width: 100%;
}
.htaCard {
  position: relative;
  min-width: 30%;
}
.sparksIconWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.htaCard > svg {
  z-index: 1;
}

.brokenLine {
  border-top: 0.7px dashed #cecdcd;
  width: 100%;
  position: absolute;
  top: 20%;
}

.htaCard > div h4 {
  font-family: var(--genesys-font-roboto);
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: 26px;
  letter-spacing: 0px;
  color: #17255a;
  margin-top: 50px;
}
.sparksIconWrapper > p {
  font-family: var(--genesys--font-roboto);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.09958337992429733px;
  color: #7d7d7d;
  max-width: 70%;
  text-align: center;
  margin-top: 14px;
}
.htaCardWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 80%;
  margin: 30px auto;
}
.htaCardWrapper > div {
  padding: 0px 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 120px;
}
.htaCardWrapper > div:last-child,
.htaCardWrapper > div:nth-child(4) {
  margin-bottom: 64px;
}

.contactUsButton {
  margin-bottom: 100px;
}

@media screen and (max-width: 1110px) {
  .htaCardWrapper > div:nth-child(4) {
    margin-bottom: 120px;
  }

  .htaCard {
    max-width: 50%;
  }
}

.sparksButtonWrapper {
  display: flex;
  justify-content: center;
}
.faqContainer {
  display: flex;
  flex-direction: row;
  margin: 70px 0px;
}

.faqContainer > div:first-of-type {
  width: 50%;
  margin-bottom: 0px;
}

.faqContainer > div:last-of-type {
  width: 50%;
}
.faqContainer h2 {
  margin-bottom: 0px;
}
.contact-button-a {
  display: none;
  margin-top: 20px;
}
.contact-button-b {
  display: block;
  margin: 40px auto 0 auto;
}
.mentorsIconsWrapper {
  width: 83%;
  margin: auto;
  padding: 50px 0px;
  display: flex;
  align-items: center;
}
.mentorsIconsWrapper > div img {
  margin-right: 50px;
}
.mentorsText {
  font-family: var(--genesys-font-roboto);
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  text-align: center;
  margin-top: 50px;
}
.sHeader {
  padding-top: 20px;
  padding-left: 16px;
}
@media screen and (max-width: 768px) {
  .sparksWrapper h1 ~ p {
    margin-top: 10px;
  }
  .htaCard > div h4 {
    font-size: 1.25rem;
    margin: 0 37px;
    text-align: left;
  }
  .sparksIconWrapper > p {
    font-size: 1rem;
    margin: 0px 100px;
    text-align: left;
  }

  .htaCard {
    flex-direction: row;
    max-width: 100%;
  }

  .sparksIconWrapper {
    display: flex;
    align-items: flex-start;
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .sparksIconWrapper > p {
    text-align: left;
    margin: 0 37px;
    margin-top: 16px;
  }

  .brokenLine {
    border-top: 0;
    border-left: 0.7px dashed #cecdcd;
    left: 47px;
    top: 0%;
    height: 100%;
    width: 90%;
  }
  .sparksimg {
    width: 100%;
  }
  .sparksGoalCard {
    width: 100%;
    margin-bottom: 20px;
  }
  .sparksimgWrapper {
    width: 100%;
    margin: 0;
  }
  .sparksHeadWrapper {
    width: 100%;
  }
  .sparksHeadWrapper > h1 {
    font-size: 34px;
  }
  .sparksHeadWrapper > p {
    font-size: 16px;
  }
  .sparksHeadWrapper > Button {
    margin: 30px 0px;
  }
  .sparksGoalCardBigWrapper {
    display: block;
    margin-top: 40px;
  }
  .sparksGoalCardWrapper {
    margin: 0px;
    height: inherit;
    width: 100%;
  }
  .sparksGoalCardWrapper2 {
    height: inherit;
    width: 100%;
  }
  .lpCardWrapper {
    display: block;
    padding: 0px;
  }
  .lpCard {
    width: 100%;
    margin: 56px 0 20px 0px;
  }
  .lpCard1 {
    margin: 0px;
    width: 100%;
  }
  .lpCard > div,
  .lpCard1 > div {
    width: 95%;
    margin: auto;
  }
  .lpImg1 {
    width: 100%;
    object-fit: cover;
  }

  .htaCard svg {
    width: 65px;
    height: 65px;
  }
  .htaCardWrapper > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0px !important;
  }

  .htaCardWrapper {
    width: 100%;
    flex-direction: column;
    margin: 60px auto;
  }

  .faqContainer {
    display: flex;
    flex-direction: column;
    margin: 0px;
  }
  .faqContainer > div:first-of-type {
    margin: 60px 0px;
    width: 100%;
  }
  .faqContainer > div:last-of-type {
    width: 100%;
  }
  .contactUsButton {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 426px) {
  .htaCardWrapper {
    display: flex;
    flex-wrap: wrap;
  }
  .htaCardWrapper > div {
    width: 100%;
  }

  .mentorsIconsWrapper > div img {
    width: 142px;
  }
  .mentorsIconsWrapper {
    width: 100%;
    margin: auto;
    padding: 0px;
    display: flex;
    flex-wrap: wrap;
  }
  .mentorsIconsWrapper > div:first-of-type {
    width: 100%;
    margin: 30px 0px;
    display: flex;
  }
  .mentorsIconsWrapper > div:last-of-type {
    display: flex;
    width: 100%;
  }
}
@media screen and (min-width: 1200px) {
  .contact-button-a {
    display: block;
  }

  .contact-button-b {
    display: none;
  }
}
