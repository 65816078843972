.basicCardContainer {
  border: 1px solid #eeeeee;
  border-radius: 32px;
  background-color: var(--codeville-color-white);
  width: fit-content;
  max-width: 347px;
  padding: 40px;
  padding-right: 61px;
  padding-bottom: 55px;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
}

.basicCardContainer:hover {
  box-shadow: 0px 23.7671px 49.2947px #dfe0e0;
}

.cardVector {
  margin-bottom: 24px;
}

.cardVector svg {
  width: 75px;
  height: 75px;
}

.basicCardContainer h2 {
  font-size: 20px;
  line-height: 135%;
  color: var(--codeville-color-space-cadet);
  margin-bottom: 16px;
}

.basicCardContainer p {
  width: 100%;
  width: 247px;
  font-size: 16px;
  font-weight: 300;
  line-height: 145%;
  color: var(--codeville-text-color);
}

/* Review card */
.reviewCardContainer {
  width: 100%;
  max-width: 1085px;
  height: fit-content;
  display: flex;
  position: relative;
  background-color: var(--codeville-color-white);
  z-index: 1;
  border-radius: 32px;
}

.reviewCardItem {
  padding: 82px 104px;
  padding-bottom: 71px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  box-shadow: 0px 23.7671px 49.2947px rgba(77, 77, 77, 0.15);
  border-radius: 32px;
  min-height: 482px;
  background-image: url("data:image/svg+xml,%3Csvg width='435' height='219' viewBox='0 0 435 219' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.1'%3E%3Crect x='215' y='195' width='24' height='24' rx='12' fill='%23FA5C03'/%3E%3Crect x='410' width='25' height='25' rx='12.5' fill='%23FDD19C'/%3E%3Crect y='84' width='28' height='27' rx='13.5' fill='%23438991'/%3E%3C/g%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: right 140px top 113px;
}

.codeVilleLearningPathCard {
  background: #fff;
  border: 1px solid rgba(238, 238, 238, 1);
  padding: 40px 30px;
  border-radius: 32px;
  color: #596275;
  min-height: 504px;
  transition: 0.5s;
}

.codeVilleLearningPathCard:hover {
  transform: scale(1.05);
}

.codeVilleLearningPathCard h3 {
  color: var(--codeville-color-space-cadet);
  font-size: 24px;
  font-weight: 600;
  width: 50%;
  line-height: 32.4px;
}

.codeVilleLearningPathCard div:first-of-type {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stackList {
  font-weight: 600;
}

.stackList::before {
  display: inline-block;
  content: "";
  width: 10px;
  height: 10px;
  border: 1px solid var(--codeville-color-space-cadet);
  border-radius: 50%;
  margin-right: 6px;
}

.codeVilleLearningPathCard div:nth-of-type(2) {
  margin-top: 14px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 300;
  line-height: 23px;
}

.codeVilleLearningPathCard div:last-of-type {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
}

@media screen and (max-width: 1100px) {
  .reviewCardItem {
    padding: 82px 30px;
  }
}

.reviewCommentContainer {
  display: flex;
  align-items: center;
  margin-bottom: 26px;
}

.reviewQuoteIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 120px;
  min-width: 120px;
  margin-right: 20px;
}

.reviewComment {
  width: 100%;
}

.reviewComment p {
  width: 100%;
  font-weight: 300;
  font-size: 21px;
  line-height: 145%;
  color: #6d7689;
  max-width: 690px;
}

.reviewerInfoContainer {
  display: flex;
}

.reviewerAvatar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

.reviewerAvatar img {
  height: 120px;
  width: 120px;
}

.reviewerInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.reviewerInfo h2,
.reviewerInfo h5 {
  font-size: 32px;
  line-height: 145%;
  color: var(--codeville-color-space-cadet);
}

.reviewerInfo h5 {
  font-size: 16px;
  font-weight: 400;
  max-width: 380px;
}

/* Indicators */
.indicatorContainer {
  position: absolute;
  bottom: 68px;
  right: 85px;
  display: flex;
}

.indicator {
  margin-left: 4px;
  margin-right: 4px;
  cursor: pointer;
}

.indicator.activeIndicator circle {
  fill: var(--codeville-color-sunset-orange);
}

@media screen and (max-width: 600px) {
  .reviewCardItem {
    min-height: fit-content;
    padding: 40px 24px;
    padding-bottom: 73px;
  }

  .reviewCommentContainer {
    flex-direction: column;
    justify-content: flex-start;
    min-height: unset;
  }

  .reviewQuoteIcon {
    width: 100%;
    justify-content: flex-start;
    padding-left: 10px;
    min-height: fit-content;
    margin-bottom: 32px;
  }
}
@media screen and (max-width: 500px) {
  .reviewCardItem {
    background-position: right 50% top 20px;
  }
  .reviewerAvatar img {
    height: 80px;
    width: 80px;
  }

  .reviewComment p {
    font-size: 14px;
  }

  .reviewerInfo h2 {
    font-size: 18px;
  }

  .reviewerInfo h5 {
    font-size: 12px;
  }

  .indicatorContainer {
    bottom: 33px;
    left: 50%;
    transform: translateX(-50%);
    justify-content: center;
  }

  .reviewQuoteIcon svg {
    width: 23.63px;
    height: 16px;
  }
}

/* Program card container */
.programCardContainer {
  width: 100%;
  min-width: 300px;
  max-width: 300px;

  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  border-radius: 32px;
  border: 1px solid transparent;
}

.programCardContainer.entry:hover {
  border-color: #438991;
}
.programCardContainer.junior:hover {
  border-color: #05a879;
}
.programCardContainer.advanced:hover {
  border-color: #fa5c03;
}
.programCardContainer.advancedVirtual:hover {
  border-color: #ffa069;
}

.programCardContainer:hover {
  filter: drop-shadow(0px 23.7671px 49.2947px rgba(96, 96, 96, 0.14));
}

.programCardContainer h1 {
  font-weight: 600;
  font-size: 22px;
  line-height: 135%;
  color: var(--codeville-color-space-cadet);
  margin-bottom: 9px;
  margin-top: 24px;
  text-align: center;
}

.programCardHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 32px 32px 0px 0px;
  padding-bottom: 24px;
  padding-top: 24px;
}

.programCardHeader p {
  max-width: 363px;
  text-align: center;
  font-weight: 300;
  font-size: 13px;
  line-height: 145%;
  text-align: center;
  color: #596275;
}

.programAge {
  width: 231px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 17px;
  border-radius: 0px 0px 12px 12px;
  color: var(--codeville-color-white);
  font-weight: bold;
  font-size: 20px;
  line-height: 16px;
  margin-bottom: 24px;
}

.programComment {
  padding: 24px 30px;
  border-radius: 0px 0px 32px 32px;
  background-color: var(--codeville-color-white);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.programComment p {
  color: #8b94a7;
  font-weight: 300;
  font-size: 16px;
  line-height: 145%;
  margin-bottom: 40px;
}

@media screen and (max-width: 450px) {
  .programComment p {
    text-align: center;
  }
}
